import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { SubSink } from "@harvestr-client/shared/shared/util-helper";
import { distinctUntilChanged, tap } from "rxjs";
import { LoadingToasterService } from "./loading-toaster.service";
import { ToastCustom, ToasterService } from "./toaster.service";

/**
 * The alert component passes alert messages to the template
 * whenever a message is received from the alert service. It does
 * this by subscribing to the alert service's getMessage() method
 * which returns an Observable.
 */
@Component({
    selector: "hx-toaster-container",
    template: `
        <div class="loading-toaster flex-center" *ngIf="showLoadingToaster">
            <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    `,
    styleUrls: ["./toaster.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToasterComponent implements OnInit, OnDestroy {
    private sub = new SubSink();

    showLoadingToaster = false;

    constructor(
        private cd: ChangeDetectorRef,
        private alertService: ToasterService,
        private loadingToasterService: LoadingToasterService,
        public snackBar: MatSnackBar,
        private router: Router
    ) {}

    ngOnInit() {
        const watchLoadingToaster$ =
            this.loadingToasterService.loadingToaster$.pipe(
                distinctUntilChanged(),
                tap(message => {
                    if (message) {
                        this.showLoadingToaster = true;
                    } else {
                        this.showLoadingToaster = false;
                    }
                    this.cd.markForCheck();
                })
            );
        const watchMessage$ = this.alertService.getMessage().pipe(
            tap(message => {
                if (message !== undefined) {
                    switch (message.type) {
                        case "custom":
                            this.openCustom(message);
                            break;
                        case "success":
                            this.openSuccessSnackBar(message.text, "Ok");
                            break;
                        case "info":
                            this.openInfoSnackBar(message.text, "Ok");
                            break;
                        case "error":
                            this.openErrorSnackBar(message.text, "Ok");
                            break;
                        case "problem":
                            this.openProblemSnackBar(
                                message.text,
                                "Reload page"
                            );
                            break;
                        case "clear":
                            this.snackBar.dismiss();
                            break;
                    }
                    this.cd.markForCheck();
                }
            })
        );
        this.sub.add(
            watchLoadingToaster$.subscribe(),
            watchMessage$.subscribe()
        );
    }

    private openCustom(data: ToastCustom) {
        const { message, action, duration, navigate, navigationOptions } = data;
        const ref = this.snackBar.open(message, action, {
            duration: duration || 2000,
            horizontalPosition: "end"
        });
        if (navigate && navigationOptions) {
            ref.onAction().subscribe(_ => {
                this.router.navigate(
                    navigationOptions.commands,
                    navigationOptions.extras
                );
            });
        }
    }

    private openSuccessSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: "end"
        });
    }

    private openErrorSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 4000,
            horizontalPosition: "end",
            panelClass: "app-toaster"
        });
    }

    private openInfoSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            panelClass: ["info-toast", "app-toaster"],
            horizontalPosition: "end"
        });
    }

    private openProblemSnackBar(message: string, action: string) {
        this.snackBar
            .open(message, action, {
                panelClass: ["problem-toast", "app-toaster"],
                horizontalPosition: "end"
            })
            .onAction()
            .subscribe(_resp => {
                window.location.reload();
            });
    }
    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
