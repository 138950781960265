import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Host,
    Input,
    OnDestroy,
    Optional,
    Output
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatMenu } from "@angular/material/menu";
import {
    EMPTY_EL_ID,
    HxMenuEl,
    HxMenuGroup
} from "@harvestr-client/shared/model/app";
import { notEmpty, SubSink } from "@harvestr-client/shared/shared/util-helper";
import { BehaviorSubject, Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { DisplayableEl, hxMenuDataHelpers } from "./hx-menu-data.helpers";
import { HxSelectedLineService } from "./hx-selected-line.service";

type HxMenuWidth = "SMALL" | "MEDIUM" | "LARGE" | "CHUNK_POPUP";

const VERTICAL_SCROLLBAR_HEIGHT_PX = 0; //20;
const LINE_HEIGHT = 40;
const MAX_HEIGHT = 220;
const EMPTY_HEIGHT = 60;

const FormProvider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HxMenuComponent),
    multi: true
};

function getMenuWidth(value: HxMenuWidth) {
    switch (value) {
        case "SMALL":
            return 240;
        case "LARGE":
            return 460;
        case "CHUNK_POPUP":
            return 540;
        case "MEDIUM":
        default:
            return 320;
    }
}

export type HxSelected<T> = HxMenuEl<T> | HxMenuEl<T>[];

@Component({
    selector: "hx-menu",
    templateUrl: "./hx-menu.component.html",
    styleUrls: ["./hx-menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [HxSelectedLineService, FormProvider]
})
export class HxMenuComponent<T> implements AfterViewInit, OnDestroy {
    private _viewInit = false;
    private _searchQuery = "";
    private _data: HxMenuEl<T>[] = [];
    private _groups: HxMenuGroup<T>[] = [];
    private subsink = new SubSink();
    private _displayDebouncer$$: Subject<boolean> = new Subject<boolean>();

    displayableData: DisplayableEl<T>[] = [];
    displayData: DisplayableEl<T>[] = [];
    height = 0;
    dropdownWidth = 320;

    selectedElsMap = new Map<string | undefined, HxMenuEl<T> | null>();
    partialSelectedIdsMap = new Map<string | undefined, boolean>();
    selectedEls: HxMenuEl<T>[] = [];

    get emptyHeight() {
        return !this.hideEmptyResultPlaceholder //&& !this._searchQuery?.length
            ? EMPTY_HEIGHT
            : 0;
    }

    get defaultMaxHeight() {
        return MAX_HEIGHT;
    }

    @Input() hideEmptyResultPlaceholder = false;

    // ? only works with single select
    @Input() disableUnselect = true;

    @Input() set virtualElementIds(_ids: string[]) {
        const ids = _ids || [];
        this.virtualSelectedIdsMap = new Map<string, boolean>();
        for (const id of ids) {
            this.virtualSelectedIdsMap.set(id, true);
        }
    }
    virtualSelectedIdsMap = new Map<string, boolean>();

    @Input() set data(d: HxMenuEl<T>[]) {
        this._data = d;
        this.data$$.next(d);
        this._setupDisplayableData();
        this._setSelection();
        this._displayData();
    }
    get data() {
        return this._data;
    }

    private data$$ = new BehaviorSubject<HxMenuEl<T>[]>([]);
    data$ = this.data$$.asObservable();

    @Input() set groups(g: HxMenuGroup<T>[]) {
        this._groups = g;
        this._setupDisplayableData();
        this._setSelection();
        this._displayData();
    }

    get groups() {
        return this._groups;
    }

    @Input() set selected(selected: HxSelected<T> | null) {
        // ? selected (array if multiselect)
        this.selectedEls = selected
            ? this._convertToSelectedEls(selected).filter(e => !!e?.id)
            : [];
        const partialSelectedIds = (this.selectedEls || [])
            .filter(e => e?.partial)
            .map(e => e?.id)
            .filter(Boolean);

        this._resetSelectedElementsMap(this.selectedEls, partialSelectedIds);
        if (this.selectionAsTag) {
            this._displayData();
        }
        console.log("SELECTED: ", {
            selected,
            selectedEls: this.selectedEls,
            selectedElsMap: this.selectedElsMap
        });
    }

    @Input() set query(val: string) {
        this._searchQuery = val;
        if (this._viewInit) {
            this.queryChange.emit(this._searchQuery || "");
        }
    }

    get query() {
        return this._searchQuery;
    }
    // ? options
    @Input() set width(value: HxMenuWidth) {
        this.dropdownWidth = getMenuWidth(value);
    }
    @Input() placeholder = "";
    @Input() multiselect = false;
    @Input() searchable = false;
    @Input() externalSearch = false;
    @Input() loading = false;
    @Input() withCreate = false;
    @Input() selectionAsTag = false;
    @Input() showSelected = false;
    @Input() createLineCustomLabel = "Create:";
    @Input() maxHeight = MAX_HEIGHT;
    // ? Outputs
    @Output() create = new EventEmitter<string>();
    @Output() selectedChange = new EventEmitter<HxSelected<T> | null>();
    @Output() queryChange = new EventEmitter<string>();

    constructor(
        private cd: ChangeDetectorRef,
        @Optional() @Host() private hostMatMenu: MatMenu
    ) {
        const searchQueryChange$ = this.queryChange.pipe(debounceTime(100));
        this.subsink.add(
            this._displayDebouncer$$.pipe(debounceTime(10)).subscribe(_ => {
                this._displayDataDebounced();
            }),
            searchQueryChange$.subscribe(_change => {
                if (!this.externalSearch) {
                    this._displayData();
                }
            })
        );
    }

    trackById = (i: number, item: { id: string }) => item.id;

    ngAfterViewInit() {
        this._viewInit = true;
        if (this.hostMatMenu) {
            this.hostMatMenu.panelClass = "hx-menu";
        }
    }

    private _setupDisplayableData() {
        this.displayableData = hxMenuDataHelpers.getDisplayableData({
            data: this.data,
            groups: this.groups
        });
    }

    private _convertToSelectedEls(value: HxSelected<T>) {
        if (Array.isArray(value)) {
            return value as HxMenuEl<T>[];
        } else if (value) {
            return [value as HxMenuEl<T>];
        } else {
            return [];
        }
    }

    private _resetSelectedElementsMap(
        selectedEls: HxMenuEl<T>[],
        partialSelectedIds: string[]
    ) {
        this.selectedElsMap = new Map<string, HxMenuEl<T> | null>();
        for (const selectedEl of selectedEls) {
            this.selectedElsMap.set(selectedEl.id, selectedEl);
        }
        for (const partialSelectedId of partialSelectedIds) {
            this.partialSelectedIdsMap.set(partialSelectedId, true);
        }
        this.cd.markForCheck();
    }

    private _displayData() {
        this._displayDebouncer$$.next(true);
    }

    // ? this function shall not be used by itself, use _displayData() instead
    private _displayDataDebounced() {
        const dataIsGroupped = hxMenuDataHelpers.isDataGroupped({
            data: this.data,
            groups: this.groups
        });
        this.displayData = hxMenuDataHelpers.getDisplayData(
            {
                dataIsGroupped,
                displayableData: this.displayableData,
                query: !this.externalSearch ? this.query : undefined,
                selectedElsMap: this.selectedElsMap
            },
            {
                withCreate: this.withCreate,
                selectionAsTag: this.selectionAsTag
            }
        );
        const linesNumber = (this.displayData || []).length;
        this.height = linesNumber
            ? Math.min(linesNumber * LINE_HEIGHT, this.maxHeight) +
              VERTICAL_SCROLLBAR_HEIGHT_PX
            : this.emptyHeight;
        this.cd.detectChanges();
        // ? force menu to re-position
        window.dispatchEvent(new Event("resize"));
    }

    onTabIndexSelect(index: number) {
        const el = (this.displayData || []).find(e => e?.tabIndex === index);
        if (!el) {
            return;
        }
        this.onLineSelect(el);
    }

    onTagSelect(el: HxMenuEl<T>, value?: boolean) {
        const displayableEl: HxMenuEl<T> &
            Pick<DisplayableEl<T>, "displayType"> = {
            ...el,
            displayType: "DEFAULT"
        };
        this.onLineSelect(displayableEl, value);
    }

    onLineSelect(
        el: HxMenuEl<T> & Pick<DisplayableEl<T>, "displayType">,
        value?: boolean
    ) {
        if (
            el.disable ||
            (this.multiselect === false &&
                this.disableUnselect &&
                this.selectedElsMap.has(el.id))
        ) {
            return;
        }

        switch (el?.displayType) {
            case "CREATE":
                this.create.emit(this.query);
                break;
            default:
                this._onNotCreateLineSelect(el, value);
                break;
        }
    }

    private _onNotCreateLineSelect(
        el: HxMenuEl<T> & Pick<DisplayableEl<T>, "displayType">,
        value?: boolean
    ) {
        const checkedState =
            typeof value === "boolean"
                ? value
                : !this.selectedElsMap.get(el.id);
        if (this.multiselect) {
            const isPartialSelected = !!this.partialSelectedIdsMap.get(el.id);
            if (isPartialSelected) {
                this.selectedElsMap.set(el.id, null);
                this.partialSelectedIdsMap.set(el.id, false);
            } else {
                this.selectedElsMap.set(el.id, checkedState ? el : null);
                this.partialSelectedIdsMap.set(el.id, false);
            }
        } else {
            this.selectedElsMap = new Map();
            this.selectedElsMap.set(el.id, checkedState ? el : null);
            this.partialSelectedIdsMap = new Map();
            this.partialSelectedIdsMap.set(el.id, false);
        }

        this._setSelection();
        // ? hide selection if display as tag
        if (this.multiselect) {
            this._displayData();
        }
        this._displayData();
        this._emitSelection();
        this.onTouch();
    }

    private _setSelection() {
        this.selectedEls = [...this.selectedElsMap.values()].filter(notEmpty);
    }

    private _emitSelection() {
        let selectChange: HxMenuEl<T> | HxMenuEl<T>[] | null = this.multiselect
            ? this.selectedEls
            : this.selectedEls[0] ?? null;
        if (this.selectedEls.some(e => e.id === EMPTY_EL_ID)) {
            selectChange = null;
        }
        if (this.multiselect) {
            if (Array.isArray(selectChange) || selectChange === null) {
                const selectionWithPartialPopulate = (
                    (selectChange as HxMenuEl<T>[]) || []
                ).map(el => ({
                    ...el,
                    partial: !!this.partialSelectedIdsMap.get(el.id)
                }));
                this.selectedChange.emit(selectionWithPartialPopulate);
                this.onModelChange(selectionWithPartialPopulate);
            }
        } else {
            this.selectedChange.emit(selectChange);
            this.onModelChange(selectChange);
        }
    }

    ngOnDestroy() {
        this.subsink.unsubscribe();
    }

    /**
     * ? Form related
     */
    onTouch = () => {
        //
    };
    private onModelChange = (_selectedEls: HxSelected<T> | null) => {
        //
    };

    writeValue(selected: HxSelected<T>) {
        const selectedEls = this._convertToSelectedEls(selected).filter(
            e => !!e?.id
        );
        const partialSelectedIds = (selectedEls || [])
            .filter(e => e?.partial)
            .map(e => e?.id)
            .filter(Boolean);
        this._resetSelectedElementsMap(selectedEls, partialSelectedIds);
        this._setSelection();
        // ? hide selection if display as tag
        if (this.selectionAsTag) {
            this._displayData();
        }
    }

    registerOnChange(fn: (selected: HxSelected<T> | null) => void) {
        this.onModelChange = fn;
    }

    registerOnTouched(fn: () => void) {
        this.onTouch = fn;
    }
    // ? Form related end

    forceProfilePictureData(el: HxMenuEl<T>) {
        if (el?.type === "PERSON") {
            return el?.pictureData;
        }
        return undefined;
    }
}
