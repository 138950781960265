import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedNgUtilNgModule } from "@harvestr-client/shared/ng/util-ng";
import { FeedbackCardComponent } from "./feedback-card.component";

@NgModule({
    imports: [CommonModule, SharedNgUtilNgModule],
    declarations: [FeedbackCardComponent],
    exports: [FeedbackCardComponent]
})
export class SharedNgUiFeedbackCardModule {}
