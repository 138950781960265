import { Person_Type, Seat_Type } from "@harvestr-client/shared/model/api";
import {
    DisplayHxMenuLineType,
    HxMenuEl,
    HxMenuGroup,
    HxMenuLineType
} from "@harvestr-client/shared/model/app";
import {
    hxColorClassMap,
    personSeatTypeToDisplay
} from "@harvestr-client/shared/ng/util-display-converter";
import { searchHelper } from "@harvestr-client/shared/shared/util-helper";
import { HxSelected } from "./hx-menu.component";

export type DisplayableEl<T> = HxMenuEl<T> & {
    tabIndex: number;
    cssColor: string | null;
    groupId?: number;
    displayType: HxMenuLineType | DisplayHxMenuLineType;
    separatorTop?: boolean;
    isCollaborator?: string;
};

export interface MultiselectState {
    partial: boolean;
    initFullSelectedTagIds: string[];
    initPartialSelectedTagIds: string[];
    fullSelectedTagIds: string[];
    partialSelectedTagIds: string[];
}

function isDataGroupped<T>(payload: {
    data: HxMenuEl<T>[];
    groups: HxMenuGroup<T>[];
}) {
    return !!payload?.groups?.length;
}

interface MinPersonType {
    type: Person_Type;
    seatType: Seat_Type;
}

function getDisplayableData<T>(payload: {
    data: HxMenuEl<T>[];
    groups: HxMenuGroup<T>[];
}) {
    const dataIsGroupped = isDataGroupped(payload);
    const { groups, data } = payload;
    if (dataIsGroupped) {
        return (groups || []).flatMap((group, j) => {
            return (group?.els || []).map(
                (e, i) =>
                    ({
                        ...e,
                        displayType: e.type || "DEFAULT",
                        tabIndex: j * 1000000 + (i + 1),
                        cssColor: hxColorClassMap[e.color || ""],
                        isCollaborator:
                            e.type === "PERSON" &&
                            (e.el as MinPersonType)?.type === "COLLABORATOR"
                                ? personSeatTypeToDisplay(
                                      (e.el as MinPersonType)?.seatType
                                  )
                                : undefined,
                        groupId: j
                    }) satisfies DisplayableEl<T>
            );
        });
    } else {
        return (data || []).map(
            (e, i) =>
                ({
                    ...e,
                    displayType: e.type || "DEFAULT",
                    tabIndex: i + 1,
                    cssColor: hxColorClassMap[e.color || ""],
                    isCollaborator:
                        e.type === "PERSON" &&
                        (e.el as MinPersonType)?.type === "COLLABORATOR"
                            ? personSeatTypeToDisplay(
                                  (e.el as MinPersonType)?.seatType
                              )
                            : undefined
                }) satisfies DisplayableEl<T>
        );
    }
}

const _hideSelectedFromDisplayedData = (
    isSelected: boolean,
    selectionAsTag: boolean
) => {
    if (!selectionAsTag) {
        return true;
    } else {
        return !isSelected;
    }
};

const _addSeparator = (
    index: number,
    els: DisplayableEl<any>[],
    dataIsGroupped: boolean
) => {
    if (!dataIsGroupped || index < 1) {
        return false;
    } else {
        return els[index - 1]?.groupId !== els[index]?.groupId;
    }
};

const createLine: DisplayableEl<any> = {
    type: "DEFAULT",
    id: "CREATE_LINE",
    value: "create",
    cssColor: null,
    tabIndex: 0,
    displayType: "CREATE"
};

function getDisplayData<T, Y>(
    payload: {
        dataIsGroupped: boolean;
        displayableData: DisplayableEl<T>[];
        query: string | undefined;
        selectedElsMap: Map<string | undefined, Y | null>;
    },
    options: {
        withCreate: boolean;
        selectionAsTag: boolean;
    }
) {
    const { dataIsGroupped, displayableData, query, selectedElsMap } = payload;
    const { withCreate, selectionAsTag } = options;
    const regexp = searchHelper.getSearchRegex(query || "");
    const nonGroupedData = (displayableData || []).filter(
        val =>
            _hideSelectedFromDisplayedData(
                selectedElsMap.get(val?.id) ? true : false,
                selectionAsTag
            ) &&
            searchHelper.filterByQuery({
                regexp,
                searchable: [val.value || ""]
            })
    );
    if (query?.length && withCreate && !nonGroupedData?.length) {
        nonGroupedData.unshift(createLine);
    }
    return nonGroupedData.map((e, i) => ({
        ...e,
        separatorTop: _addSeparator(i, nonGroupedData, dataIsGroupped)
    }));
}

function getMultipleElements<T>(els: HxSelected<T>) {
    return Array.isArray(els) ? els : [els];
}

function getSingleElement<T>(els: HxSelected<T> | null) {
    if (els === null) {
        return null;
    }
    const _els = getMultipleElements(els);
    return _els?.[0];
}

function buildMultiselectHxSelectedElements<T>(data: {
    list: HxMenuEl<T>[];
    fullSelectedIds: string[];
    partialSelectedIds?: string[];
}): HxMenuEl<T>[] {
    const list = data?.list || [];
    const fullSelected = list
        .filter(e => (data?.fullSelectedIds || []).includes(e?.id))
        .map(el => ({
            ...el,
            partial: false
        }));
    const partialSelected = list
        .filter(e => (data?.partialSelectedIds || []).includes(e?.id))
        .map(el => ({
            ...el,
            partial: true
        }));
    return [...fullSelected, ...partialSelected];
}

function buildMultiselectSelectedIds<T extends { id: string }>(
    list: T[],
    selectedElementCount: number
) {
    const elementList: Array<T & { count: number }> = [];
    (list || []).forEach(tag => {
        const foundInList = elementList.find(_t => _t?.id === tag?.id);
        if (foundInList) {
            foundInList.count++;
        } else {
            elementList.push({ ...tag, count: 1 });
        }
    });
    return {
        fullSelectedIds: elementList
            .filter(el => el.count === selectedElementCount)
            .map(el => el.id),
        partialSelectedIds: elementList
            .filter(el => el.count !== selectedElementCount)
            .map(el => el.id)
    };
}

function connectDisconnectMultiselectState(state: MultiselectState): {
    connect?: string[];
    disconnect?: string[];
} | null {
    const connect = state.fullSelectedTagIds;
    const disconnect = [
        ...(state.initFullSelectedTagIds || []),
        ...(state.initPartialSelectedTagIds || [])
    ].filter(
        id =>
            ![
                ...state.fullSelectedTagIds,
                ...state.partialSelectedTagIds
            ].includes(id)
    );

    if (!connect?.length && !disconnect?.length) {
        return null;
    }

    return {
        connect,
        disconnect
    };
}

export const hxMenuDataHelpers = {
    isDataGroupped,
    getDisplayableData,
    getDisplayData,
    getSingleElement,
    getMultipleElements,
    buildMultiselectHxSelectedElements,
    buildMultiselectSelectedIds,
    connectDisconnectMultiselectState
};
