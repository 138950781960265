type Entries<T> = {
    [K in keyof T]: [K, T[K]];
}[keyof T][];

const ObjectEntriesTyped = <T extends object>(obj: T) =>
    Object.entries(obj) as Entries<T>;

export const iso639CountryCodeLanguage = {
    ab: "Abkhazian" as const,
    aa: "Afar" as const,
    af: "Afrikaans" as const,
    sq: "Albanian" as const,
    am: "Amharic" as const,
    ar: "Arabic" as const,
    hy: "Armenian" as const,
    as: "Assamese" as const,
    ay: "Aymara" as const,
    az: "Azerbaijani" as const,
    ba: "Bashkir" as const,
    eu: "Basque" as const,
    bn: "Bengali (Bangla)" as const,
    dz: "Bhutani" as const,
    bh: "Bihari" as const,
    bi: "Bislama" as const,
    br: "Breton" as const,
    bg: "Bulgarian" as const,
    my: "Burmese" as const,
    be: "Byelorussian (Belarusian)" as const,
    km: "Cambodian" as const,
    ca: "Catalan" as const,
    zh: "Chinese (Traditional)" as const,
    co: "Corsican" as const,
    hr: "Croatian" as const,
    cs: "Czech" as const,
    da: "Danish" as const,
    nl: "Dutch" as const,
    en: "English" as const,
    eo: "Esperanto" as const,
    et: "Estonian" as const,
    fo: "Faeroese" as const,
    fa: "Farsi" as const,
    fj: "Fiji" as const,
    fi: "Finnish" as const,
    fr: "French" as const,
    fy: "Frisian" as const,
    gl: "Galician" as const,
    gd: "Gaelic (Scottish)" as const,
    gv: "Gaelic (Manx)" as const,
    ka: "Georgian" as const,
    de: "German" as const,
    el: "Greek" as const,
    kl: "Greenlandic" as const,
    gn: "Guarani" as const,
    gu: "Gujarati" as const,
    ha: "Hausa" as const,
    he: "Hebrew" as const,
    hi: "Hindi" as const,
    hu: "Hungarian" as const,
    is: "Icelandic" as const,
    id: "Indonesian" as const,
    ia: "Interlingua" as const,
    ie: "Interlingue" as const,
    iu: "Inuktitut" as const,
    ik: "Inupiak" as const,
    ga: "Irish" as const,
    it: "Italian" as const,
    ja: "Javanese" as const,
    kn: "Kannada" as const,
    ks: "Kashmiri" as const,
    kk: "Kazakh" as const,
    rw: "Kinyarwanda (Ruanda)" as const,
    ky: "Kirghiz" as const,
    rn: "Kirundi (Rundi)" as const,
    ko: "Korean" as const,
    ku: "Kurdish" as const,
    lo: "Laothian" as const,
    la: "Latin" as const,
    lv: "Latvian (Lettish)" as const,
    li: "Limburgish (Limburger)" as const,
    ln: "Lingala" as const,
    lt: "Lithuanian" as const,
    mk: "Macedonian" as const,
    mg: "Malagasy" as const,
    ms: "Malay" as const,
    ml: "Malayalam" as const,
    mt: "Maltese" as const,
    mi: "Maori" as const,
    mr: "Marathi" as const,
    mo: "Moldavian" as const,
    mn: "Mongolian" as const,
    na: "Nauru" as const,
    ne: "Nepali" as const,
    no: "Norwegian" as const,
    oc: "Occitan" as const,
    or: "Oriya" as const,
    om: "Oromo (Afan, Galla)" as const,
    ps: "Pashto (Pushto)" as const,
    pl: "Polish" as const,
    pt: "Portuguese" as const,
    pa: "Punjabi" as const,
    qu: "Quechua" as const,
    rm: "Rhaeto-Romance" as const,
    ro: "Romanian" as const,
    ru: "Russian" as const,
    sm: "Samoan" as const,
    sg: "Sangro" as const,
    sa: "Sanskrit" as const,
    sr: "Serbian" as const,
    sh: "Serbo-Croatian" as const,
    st: "Sesotho" as const,
    tn: "Setswana" as const,
    sn: "Shona" as const,
    sd: "Sindhi" as const,
    si: "Sinhalese" as const,
    ss: "Siswati" as const,
    sk: "Slovak" as const,
    sl: "Slovenian" as const,
    so: "Somali" as const,
    es: "Spanish" as const,
    su: "Sundanese" as const,
    sw: "Swahili (Kiswahili)" as const,
    sv: "Swedish" as const,
    tl: "Tagalog" as const,
    tg: "Tajik" as const,
    ta: "Tamil" as const,
    tt: "Tatar" as const,
    te: "Telugu" as const,
    th: "Thai" as const,
    bo: "Tibetan" as const,
    ti: "Tigrinya" as const,
    to: "Tonga" as const,
    ts: "Tsonga" as const,
    tr: "Turkish" as const,
    tk: "Turkmen" as const,
    tw: "Twi" as const,
    ug: "Uighur" as const,
    uk: "Ukrainian" as const,
    ur: "Urdu" as const,
    uz: "Uzbek" as const,
    vi: "Vietnamese" as const,
    vo: "Volapük" as const,
    cy: "Welsh" as const,
    wo: "Wolof" as const,
    xh: "Xhosa" as const,
    yi: "Yiddish" as const,
    yo: "Yoruba" as const,
    zu: "Zulu" as const
};
type ValueOf<T> = T[keyof T];

export const iso639CountryCodeValues = ObjectEntriesTyped(
    iso639CountryCodeLanguage
).map(([k]) => k);
export type Iso639CountryCode = keyof typeof iso639CountryCodeLanguage;
export type Iso639CountryLanguage = ValueOf<typeof iso639CountryCodeLanguage>;
