export enum XLSX_IMPORT_SHEET {
    DISCOVERIES = "discoveries",
    FEEDBACK = "feedback",
    CONTRIBUTORS = "customers"
}

export enum FEEDBACK_KEY {
    ID = "id",
    TITLE = "title",
    CONTENT = "content",
    DISCOVERY_IDS = "discovery ids",
    CONTRIBUTOR_ID = "customer id",
    LABELS = "labels",
    CREATED_AT = "create date"
}

export enum DISCOVERY_KEY {
    ID = "id",
    TITLE = "title",
    COMPONENT = "component",
    TAGS = "tags",
    DESCRIPTION = "description",
    FIELDS = "fields",
    STATUS = "status",
    CREATED_AT = "create date"
}

export enum CONTRIBUTOR_KEY {
    ID = "id",
    USERNAME = "user name",
    COMPANY_NAME = "company name",
    EMAIL = "email",
    PHONE = "phone",
    SEGMENTS = "segments",
    ATTRIBUTES = "attributes",
    DESCRIPTION = "description",
    TYPE = "type"
}

export enum ATTRIBUTE_TYPE {
    STR,
    NUM,
    LIST_NUM,
    LIST_STR,
    LIST_BOOL,
    MAP,
    BOOL
}

export interface XLSXImportStats {
    feedback: number;
    discoveries: number;
    contributors: number;

    labels: number;
    userSegments: number;
    companySegments: number;

    discoveryTags: number;
    discoveryStates: number;
    discoveryFields: number;

    contributorAttributes: number;
}

export const CONTRIBUTOR_TYPE_DEFS = {
    [CONTRIBUTOR_KEY.ID]: ATTRIBUTE_TYPE.STR,
    [CONTRIBUTOR_KEY.USERNAME]: ATTRIBUTE_TYPE.STR,
    [CONTRIBUTOR_KEY.COMPANY_NAME]: ATTRIBUTE_TYPE.STR,
    [CONTRIBUTOR_KEY.EMAIL]: ATTRIBUTE_TYPE.STR,
    [CONTRIBUTOR_KEY.PHONE]: ATTRIBUTE_TYPE.STR,
    [CONTRIBUTOR_KEY.SEGMENTS]: ATTRIBUTE_TYPE.LIST_STR,
    [CONTRIBUTOR_KEY.ATTRIBUTES]: ATTRIBUTE_TYPE.MAP,
    [CONTRIBUTOR_KEY.DESCRIPTION]: ATTRIBUTE_TYPE.STR,
    [CONTRIBUTOR_KEY.TYPE]: ATTRIBUTE_TYPE.STR
};

export const DISCOVERY_TYPE_DEFS = {
    [DISCOVERY_KEY.ID]: ATTRIBUTE_TYPE.STR,
    [DISCOVERY_KEY.TITLE]: ATTRIBUTE_TYPE.STR,
    [DISCOVERY_KEY.COMPONENT]: ATTRIBUTE_TYPE.STR,
    [DISCOVERY_KEY.TAGS]: ATTRIBUTE_TYPE.LIST_STR,
    [DISCOVERY_KEY.DESCRIPTION]: ATTRIBUTE_TYPE.STR,
    [DISCOVERY_KEY.FIELDS]: ATTRIBUTE_TYPE.MAP,
    [DISCOVERY_KEY.STATUS]: ATTRIBUTE_TYPE.STR,
    [DISCOVERY_KEY.CREATED_AT]: ATTRIBUTE_TYPE.STR
};

export const FEEDBACK_TYPE_DEFS = {
    [FEEDBACK_KEY.ID]: ATTRIBUTE_TYPE.STR,
    [FEEDBACK_KEY.TITLE]: ATTRIBUTE_TYPE.STR,
    [FEEDBACK_KEY.CONTENT]: ATTRIBUTE_TYPE.STR,
    [FEEDBACK_KEY.DISCOVERY_IDS]: ATTRIBUTE_TYPE.LIST_STR,
    [FEEDBACK_KEY.CONTRIBUTOR_ID]: ATTRIBUTE_TYPE.STR,
    [FEEDBACK_KEY.LABELS]: ATTRIBUTE_TYPE.LIST_STR,
    [FEEDBACK_KEY.CREATED_AT]: ATTRIBUTE_TYPE.STR
};

export interface ContributorRaw {
    [CONTRIBUTOR_KEY.ID]: string;
    [CONTRIBUTOR_KEY.USERNAME]: string;
    [CONTRIBUTOR_KEY.COMPANY_NAME]: string;
    [CONTRIBUTOR_KEY.EMAIL]: string;
    [CONTRIBUTOR_KEY.PHONE]: string;
    [CONTRIBUTOR_KEY.SEGMENTS]: string;
    [CONTRIBUTOR_KEY.ATTRIBUTES]: string;
    [CONTRIBUTOR_KEY.DESCRIPTION]: string;
    [CONTRIBUTOR_KEY.TYPE]: string;
}

export interface FeedbackRaw {
    [FEEDBACK_KEY.ID]: string;
    [FEEDBACK_KEY.TITLE]: string;
    [FEEDBACK_KEY.CONTENT]: string;
    [FEEDBACK_KEY.CONTRIBUTOR_ID]: number;
    [FEEDBACK_KEY.DISCOVERY_IDS]: string;
    [FEEDBACK_KEY.LABELS]: string;
    [FEEDBACK_KEY.CREATED_AT]: string;
}

export interface DiscoveryRaw {
    [DISCOVERY_KEY.ID]: string;
    [DISCOVERY_KEY.TITLE]: string;
    [DISCOVERY_KEY.DESCRIPTION]: string;
    [DISCOVERY_KEY.FIELDS]: string;
    [DISCOVERY_KEY.COMPONENT]: string;
    [DISCOVERY_KEY.TAGS]: string;
    [DISCOVERY_KEY.STATUS]: string;
    [DISCOVERY_KEY.CREATED_AT]: string;
}

///

export interface ContributorXLSX {
    [CONTRIBUTOR_KEY.ID]: string;
    [CONTRIBUTOR_KEY.USERNAME]?: string;
    [CONTRIBUTOR_KEY.COMPANY_NAME]?: string;
    [CONTRIBUTOR_KEY.EMAIL]?: string;
    [CONTRIBUTOR_KEY.PHONE]: string;
    [CONTRIBUTOR_KEY.SEGMENTS]: string[];
    [CONTRIBUTOR_KEY.DESCRIPTION]: string;
    [CONTRIBUTOR_KEY.ATTRIBUTES]: { [x: string]: string };
    [CONTRIBUTOR_KEY.TYPE]: "company" | "user";
}

export interface FeedbackXLSX {
    [FEEDBACK_KEY.ID]: string;
    [FEEDBACK_KEY.TITLE]: string;
    [FEEDBACK_KEY.CONTENT]: string;
    [FEEDBACK_KEY.CONTRIBUTOR_ID]: string;
    [FEEDBACK_KEY.DISCOVERY_IDS]: string[];
    [FEEDBACK_KEY.LABELS]: string[];
    [FEEDBACK_KEY.CREATED_AT]: string;
}

export interface DiscoveryXLSX {
    [DISCOVERY_KEY.ID]: string;
    [DISCOVERY_KEY.TITLE]: string;
    [DISCOVERY_KEY.DESCRIPTION]: string;
    [DISCOVERY_KEY.FIELDS]: { [x: string]: string };
    [DISCOVERY_KEY.COMPONENT]: string;
    [DISCOVERY_KEY.TAGS]: string[];
    [DISCOVERY_KEY.STATUS]: string;
    [DISCOVERY_KEY.CREATED_AT]: string;
}

export interface XLSXImportPayload {
    feedback: FeedbackXLSX[];
    discoveries: DiscoveryXLSX[];
    contributors: ContributorXLSX[];
}

export class XLSXSizeLimitsError extends Error {
    constructor(
        message: string,
        public stats: XLSXImportStats,
        public limits: XLSXImportLimits,
        public limitCheckResults: XSLXLimitsCheckResults
    ) {
        super(message);
    }
}

export interface XLSXImportLimits {
    discoveriesLimit: number;
    feedbackLimit: number;
    contributorsLimit: number;
    metadataLimit: number;
}

export interface XSLXLimitsCheckResults {
    feedback: boolean;
    discoveries: boolean;
    contributors: boolean;
    metadata: boolean;
}
