import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ImageUploadAbstractServicePorts } from "@harvestr-client/shared/ng/util-injector-environment";
import { HubspotImageUploadService } from "./services/upload-attachment.service";

@NgModule({
    imports: [CommonModule],
    providers: [
        {
            provide: ImageUploadAbstractServicePorts,
            useClass: HubspotImageUploadService
        }
    ]
})
export class AppHubspotWidgetDataAccessApiRestHxModule {}
