<div class="hx-menu mat-mdc-menu-panel" *ngIf="props?.items as els">
    <div class="mat-mdc-menu-content heigh-limit-content">
        <div
            class="hx-menu-line mat-mdc-menu-item"
            [ngClass]="{ selected: i === selectedIndex }"
            *ngFor="let item of els; index as i; first as isFirst"
            (click)="selectItem(i)"
        >
            {{ item.value }}
        </div>
    </div>
</div>
