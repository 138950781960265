import {
    AfterViewInit,
    ChangeDetectorRef,
    Directive,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    OnDestroy
} from "@angular/core";
import { SubSink } from "@harvestr-client/shared/shared/util-helper";
import { HxSelectedLineService } from "../hx-selected-line.service";

@Directive({
    selector: "[hxMenuLine]"
})
export class HxMenuLineDirective implements AfterViewInit, OnDestroy {
    @Input({ required: true }) hxMenuLine: undefined;
    @Input() tabIndex: number | undefined;

    @HostBinding("class.selected") isFocused = false;
    @HostListener("mouseover") onHover() {
        this.db.selectedLine = this.tabIndex;
    }

    private subs = new SubSink();

    constructor(
        public el: ElementRef,
        private db: HxSelectedLineService,
        private cd: ChangeDetectorRef
    ) {}

    ngAfterViewInit() {
        this.subs.add(
            this.db.selectedLine$.subscribe(index => {
                const focusNew = index === this.tabIndex;
                const diff = focusNew !== this.isFocused;
                if (diff) {
                    this.isFocused = !this.isFocused;
                    this.cd.detectChanges();
                }
            })
        );
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
