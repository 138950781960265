import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Color, Person_Type } from "@harvestr-client/shared/model/api";
import { EMPTY_EL_ID, HxMenuEl } from "@harvestr-client/shared/model/app";
import { hxMenuDataHelpers } from "@harvestr-client/shared/ng/ui-hx-menu";
import { ToasterService } from "@harvestr-client/shared/ng/ui-overlay-alert-toaster";
import { SubSink } from "@harvestr-client/shared/shared/util-helper";
import { BehaviorSubject, combineLatest, EMPTY, Observable } from "rxjs";
import {
    catchError,
    debounceTime,
    filter,
    first,
    map,
    startWith,
    switchMap,
    tap
} from "rxjs/operators";
import { hxDealElementsBuilder } from "../../helpers/hx-elements.builder";
import {
    CompanyEl,
    ContactEl,
    Deal,
    DealEl,
    MessageLabelFlatFragment
} from "../../models";
import { HubspotService } from "../services/hubspot.service";

type RequesterType = "CONTACT" | "COMPANY" | "DEAL_COMPANY" | "DEAL_CONTACT";

@Component({
    templateUrl: "./hubspot-form.component.html",
    styleUrls: ["./hubspot-form.component.scss"]
})
export class HubspotFormComponent implements OnInit, OnDestroy {
    private subs = new SubSink();

    private searchContacts$$ = new BehaviorSubject<string>("");
    private searchContacts$ = this.searchContacts$$
        .asObservable()
        .pipe(startWith(""), debounceTime(300));

    private searchCompanies$$ = new BehaviorSubject<string>("");
    private searchCompanies$ = this.searchCompanies$$
        .asObservable()
        .pipe(startWith(""), debounceTime(300));

    private init$$ = new BehaviorSubject(false);
    private init$ = this.init$$.asObservable();

    private submitter: Omit<ContactEl, "name">;

    portalId$: Observable<string> = this.route.queryParams.pipe(
        map(p => p?.portalId)
    );

    possibleRequesterTypesEls: HxMenuEl<RequesterType>[] = [
        {
            type: "DEFAULT",
            id: "CONTACT",
            value: "Contact",
            el: "CONTACT"
        },
        {
            type: "DEFAULT",
            id: "COMPANY",
            value: "Company",
            el: "COMPANY"
        },
        {
            type: "DEFAULT",
            id: "DEAL_COMPANY",
            value: "Company",
            el: "DEAL_COMPANY"
        },
        {
            type: "DEFAULT",
            id: "DEAL_CONTACT",
            value: "Contact",
            el: "DEAL_CONTACT"
        }
    ];
    requesterTypeEls: HxMenuEl<RequesterType>[] = [
        {
            type: "DEFAULT",
            id: "CONTACT",
            value: "Contact",
            el: "CONTACT"
        },
        {
            type: "DEFAULT",
            id: "COMPANY",
            value: "Company",
            el: "COMPANY"
        }
    ];

    messageLabelsEls: HxMenuEl<MessageLabelFlatFragment>[] = [];
    companyEls: HxMenuEl<CompanyEl>[] = [];
    companyContactEls: HxMenuEl<ContactEl>[] = [];
    contactEls: HxMenuEl<ContactEl>[] = [];
    dealEls: HxMenuEl<DealEl>[] = [];
    dealContactsEls: HxMenuEl<ContactEl>[] = [];
    dealCompaniesEls: HxMenuEl<CompanyEl>[] = [];
    dealCompanyContactEls: HxMenuEl<ContactEl>[] = [];

    companyQuery: string = null;
    contactQuery: string = null;

    form = new FormGroup({
        title: new FormControl("", [Validators.required]),
        company: new FormControl<HxMenuEl<CompanyEl> | null>(null),
        deal: new FormControl<HxMenuEl<DealEl> | null>(null),
        dealContact: new FormControl<HxMenuEl<ContactEl> | null>(null),
        dealCompany: new FormControl<HxMenuEl<CompanyEl> | null>(null),
        dealCompanyContactControl: new FormControl<HxMenuEl<ContactEl> | null>(
            null
        ),
        companyContact: new FormControl<HxMenuEl<ContactEl> | null>(null),
        contact: new FormControl<HxMenuEl<ContactEl> | null>(null),
        labels: new FormControl<HxMenuEl<MessageLabelFlatFragment>[]>([]),
        content: new FormControl("", [Validators.required])
    });
    controls = this.form.controls;

    formSent = false;
    sendingFeedback = false;
    requesterType: HxMenuEl<RequesterType> = {
        type: "DEFAULT",
        id: "CONTACT",
        value: "Contact",
        el: "CONTACT"
    };

    get titleControl() {
        return this.controls.title;
    }

    get contentControl() {
        return this.controls.content;
    }

    get companyControl() {
        return this.controls.company;
    }

    get dealControl() {
        return this.controls.deal;
    }

    get dealControlValue() {
        return this.dealControl.value;
    }

    get dealContactControl() {
        return this.controls.dealContact;
    }

    get dealCompanyControl() {
        return this.controls.dealCompany;
    }

    get dealCompanyContactControl() {
        return this.controls.dealCompanyContactControl;
    }

    get companyContactControl() {
        return this.controls.companyContact;
    }

    get contactControl() {
        return this.controls.contact;
    }

    get labelsControl() {
        return this.controls.labels;
    }

    constructor(
        private route: ActivatedRoute,
        private hubspotService: HubspotService,
        private toasterService: ToasterService
    ) {}

    ngOnInit() {
        const messageLabelsInit$ = this.portalId$.pipe(
            switchMap(portalId => this._loadMessageLabelEls$(portalId)),
            tap(labelEls => {
                this.messageLabelsEls = labelEls;
            })
        );

        const defaultValueInit$ = this._initDefaultValues$().pipe(
            tap(() => {
                this.init$$.next(true);
            })
        );

        const searchCompanies$ = combineLatest([
            this.portalId$,
            this.searchCompanies$
        ]).pipe(
            switchMap(([portailId, search]) =>
                this._loadCompaniesEls$(portailId, search)
            ),
            tap(companyEls => {
                this.companyEls = companyEls;
            })
        );

        const getCompanyContacts$ = this.init$.pipe(
            filter(init => init),
            switchMap(() =>
                this.companyControl.valueChanges.pipe(
                    startWith(this.companyControl?.value)
                )
            ),
            filter(() => !!this.companyControl?.value?.id),
            tap(() => {
                this.companyContactControl.reset(null, { emitEvent: false });
            }),
            switchMap(() => this.portalId$),
            switchMap(portailId =>
                this._loadCompanyContactEls$(
                    portailId,
                    this.companyControl?.value?.id || ""
                )
            ),
            tap(contactEls => {
                this.companyContactEls = contactEls;
            })
        );

        const getDealCompanyContacts$ = this.init$.pipe(
            filter(init => init),
            switchMap(() =>
                this.dealCompanyControl.valueChanges.pipe(
                    startWith(this.dealCompanyControl?.value)
                )
            ),
            filter(() => !!this.dealCompanyControl?.value?.id),
            tap(() => {
                this.dealCompanyContactControl.reset(null, {
                    emitEvent: false
                });
            }),
            switchMap(() => this.portalId$),
            switchMap(portailId =>
                this._loadCompanyContactEls$(
                    portailId,
                    this.dealCompanyControl?.value?.id || ""
                )
            ),
            tap(contactEls => {
                this.dealCompanyContactEls = contactEls;
            })
        );

        const searchContacts$ = this.init$.pipe(
            filter(init => init),
            switchMap(() =>
                combineLatest([this.portalId$, this.searchContacts$])
            ),
            switchMap(([portailId, name]) =>
                this._loadContactEls$(portailId, name)
            ),
            tap(contactEls => {
                this.contactEls = contactEls;
            })
        );

        this.subs.add(
            messageLabelsInit$.subscribe(),
            defaultValueInit$.subscribe(),
            searchCompanies$.subscribe(),
            searchContacts$.subscribe(),
            getCompanyContacts$.subscribe(),
            getDealCompanyContacts$.subscribe()
        );
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    private _initDefaultValues$() {
        const setDefaultTemplate$ = this.portalId$.pipe(
            switchMap(portalId =>
                this.hubspotService.getTokenOptions(portalId)
            ),
            tap(tokenOptions => {
                const title =
                    tokenOptions?.defaultMessageTemplate?.messageTitle || "";
                const content =
                    tokenOptions?.defaultMessageTemplate?.messageContent || "";

                if (title?.length) {
                    this.titleControl.setValue(title);
                    this.titleControl.markAsDirty();
                }

                if (content?.length) {
                    this.contentControl.setValue(content);
                    this.contentControl.markAsDirty();
                }
            })
        );

        return this.route.queryParams.pipe(
            tap(queryParam => {
                const associatedObjectType = queryParam["associatedObjectType"];
                const userId = queryParam["userId"];
                const userEmail = queryParam["userEmail"];

                this.requesterType =
                    this.possibleRequesterTypesEls.find(r =>
                        r.id.includes(associatedObjectType)
                    ) || this.requesterTypeEls[0];

                this.submitter = {
                    id: userId,
                    email: userEmail,
                    type: Person_Type.Customer,
                    avatarUrl: null
                };

                this.form.reset(
                    {
                        title: "",
                        company: null,
                        companyContact: null,
                        contact: null,
                        labels: [],
                        content: ""
                    },
                    { emitEvent: false }
                );
            }),
            tap(queryParam => {
                const {
                    portalId,
                    associatedObjectId,
                    name,
                    firstname,
                    lastname,
                    email
                } = queryParam;

                switch (this.requesterType?.el) {
                    case "COMPANY":
                        this._setCompanyDefaultValue({
                            id: associatedObjectId,
                            name: name
                        });
                        break;
                    case "CONTACT":
                        this._setContactDefaultValue({
                            id: associatedObjectId,
                            firstname: firstname,
                            lastname: lastname,
                            email: email
                        });

                        break;
                    case "DEAL_CONTACT":
                    case "DEAL_COMPANY":
                        // get the deal do that we can get the associated contacts and companies
                        this.hubspotService
                            .getDealById(portalId, associatedObjectId)
                            .pipe(
                                first(),
                                tap(deal => {
                                    this._setDealWithAssociatedEls(deal);
                                    this._setDealWithAssociationsValues();
                                })
                            )
                            .subscribe();
                        break;
                }
            }),
            switchMap(() => setDefaultTemplate$)
        );
    }

    private _setCompanyDefaultValue(data: { id: string; name: string }) {
        const company: HxMenuEl<CompanyEl> = {
            type: "COMPANY",
            id: data?.id,
            value: data?.name,
            el: {
                id: data?.id,
                name: data?.name
            }
        };
        this.companyControl.setValue(company);
    }

    private _setContactDefaultValue(data: {
        id: string;
        firstname: string;
        lastname: string;
        email: string;
    }) {
        const name =
            data?.firstname && data?.lastname
                ? `${data.firstname} ${data.lastname}`
                : data?.email;

        const contact: HxMenuEl<ContactEl> = {
            type: "PERSON",
            id: data?.id,
            value: name,
            el: {
                id: data?.id,
                name,
                email: data?.email,
                type: Person_Type.Customer,
                avatarUrl: null
            },
            pictureData: {
                id: data?.id,
                name,
                email: data?.email,
                type: Person_Type.Customer,
                avatarUrl: null
            }
        };
        this.contactControl.setValue(contact);
    }

    private _loadMessageLabelEls$(portalId: string) {
        return this.hubspotService.getMessageLabels(portalId).pipe(
            map(labels =>
                (labels || []).map(label => ({
                    type: "DEFAULT" as const,
                    id: label?.id,
                    value: label?.name,
                    el: label,
                    icon: {
                        name: "icon-tag",
                        color: Color.Grey
                    }
                }))
            )
        );
    }

    private _loadCompaniesEls$(
        portalId: string,
        search: string
    ): Observable<HxMenuEl<CompanyEl>[]> {
        return this.hubspotService.getCompanies(portalId, search).pipe(
            map(companies =>
                (companies || []).map(p => ({
                    type: "COMPANY" as const,
                    id: p.id,
                    value: p.name,
                    el: p,
                    pictureData: p
                }))
            )
        );
    }

    private _loadContactEls$(
        portalId: string,
        name: string
    ): Observable<HxMenuEl<ContactEl>[]> {
        return this.hubspotService.getContacts(portalId, name).pipe(
            map(contacts =>
                (contacts || []).map(p => ({
                    type: "PERSON" as const,
                    id: p.id,
                    value: p?.name,
                    el: p,
                    pictureData: p
                }))
            )
        );
    }

    private _setDealEls(deal: Deal): void {
        this.dealEls = hxDealElementsBuilder(deal);
    }

    private _setDealWithAssociatedEls(deal: Deal): void {
        this._setDealEls(deal);
        const contacts = (deal.contacts || []).map(p => ({
            type: "PERSON" as const,
            id: p.id,
            value: p.name,
            el: p,
            pictureData: p
        }));
        const companies = (deal.companies || []).map(p => ({
            type: "COMPANY" as const,
            id: p.id,
            value: p.name,
            el: p
        }));

        this.dealContactsEls = contacts;
        this.dealCompaniesEls = companies;
    }

    private _setDealWithAssociationsValues(): void {
        const companyEl = this.dealCompaniesEls?.[0];
        const contactEl = this.dealContactsEls?.[0];
        const dealEl = this.dealEls?.[0];

        this.dealControl.setValue(dealEl);

        if (companyEl !== undefined) {
            this._swapToDealRequesterType(
                companyEl !== undefined,
                contactEl !== undefined
            );
            this.requesterType = this.requesterTypeEls.find(
                r => r.id === "DEAL_COMPANY"
            );

            this.dealCompanyControl.setValue(companyEl);
            return;
        }

        if (contactEl !== undefined) {
            this._swapToDealRequesterType(
                companyEl !== undefined,
                contactEl !== undefined
            );
            this.requesterType = this.requesterTypeEls.find(
                r => r.id === "DEAL_CONTACT"
            );
            this.dealContactControl.setValue(contactEl);
            return;
        }

        this.requesterType = this.requesterTypeEls.find(
            r => r.id === "COMPANY"
        );
    }

    private _swapToDealRequesterType(
        supportComany: boolean,
        supportContact: boolean
    ): void {
        this.requesterTypeEls = [];

        if (supportComany) {
            this.requesterTypeEls.push({
                type: "DEFAULT",
                id: "DEAL_COMPANY",
                el: "DEAL_COMPANY",
                value: "Company"
            });
        }

        if (supportContact) {
            this.requesterTypeEls.push({
                type: "DEFAULT",
                id: "DEAL_CONTACT",
                el: "DEAL_CONTACT",
                value: "Contact"
            });
        }
    }

    private _loadCompanyContactEls$(
        portalId: string,
        name: string
    ): Observable<HxMenuEl<ContactEl>[]> {
        return this.hubspotService.getComanyContacts(portalId, name).pipe(
            map(
                contacts =>
                    contacts?.map(contact => ({
                        type: "PERSON" as const,
                        id: contact.id,
                        value: contact?.name,
                        el: contact,
                        pictureData: contact
                    }))
            ),
            map(contactElements => [
                {
                    type: "PERSON" as const,
                    id: EMPTY_EL_ID,
                    value: "None",
                    color: Color.Grey,
                    el: undefined,
                    pictureData: null
                },
                ...contactElements
            ])
        );
    }

    private _getSelectedRequester(): {
        contactId?: string;
        companyId?: string;
        type: "CONTACT" | "COMPANY";
    } | null {
        switch (this.requesterType?.el) {
            case "CONTACT":
                return {
                    contactId: this.contactControl.value?.el?.id || "",
                    type: "CONTACT"
                };
            case "COMPANY":
                if (this.companyContactControl?.value?.el?.id) {
                    return {
                        contactId: this.companyContactControl.value?.el?.id,
                        type: "CONTACT",
                        companyId: this.companyControl?.value?.el?.id
                    };
                }

                return {
                    companyId: this.companyControl?.value?.el?.id || "",
                    type: "COMPANY"
                };
            case "DEAL_COMPANY":
                if (this.dealCompanyContactControl?.value?.el?.id) {
                    return {
                        contactId: this.dealCompanyContactControl.value?.el?.id,
                        type: "CONTACT",
                        companyId: this.companyControl?.value?.el?.id
                    };
                }

                return {
                    companyId: this.dealCompanyControl?.value?.el?.id || "",
                    type: "COMPANY"
                };
            case "DEAL_CONTACT":
                return {
                    contactId: this.dealContactControl.value?.el?.id || "",
                    type: "CONTACT"
                };
            default:
                return null;
        }
    }

    removeLabelFromForm(label: HxMenuEl<MessageLabelFlatFragment>) {
        const labels = this.labelsControl?.value || [];
        this.labelsControl.setValue(labels.filter(l => l.id !== label.id));
    }

    onSearchCompanies(query: string) {
        this.searchCompanies$$.next(query);
    }

    onSearchContacts(query: string) {
        this.searchContacts$$.next(query || "");
    }

    setRequesterType(el: HxMenuEl<RequesterType> | HxMenuEl<RequesterType>[]) {
        this.requesterType = hxMenuDataHelpers.getSingleElement(el);
    }

    send() {
        const requester = this._getSelectedRequester();

        if (!requester?.companyId?.length && !requester?.contactId?.length) {
            return this.toasterService.info(
                "Please select a requester before sumitting"
            );
        }

        const data = {
            messageTitle: this.titleControl.value,
            messageContent: this.contentControl.value,
            labelIds: (this.labelsControl.value || [])
                .map(el => el?.el?.id)
                .filter(Boolean),
            requester,
            submitter: this.submitter
        };

        this.portalId$
            .pipe(
                first(),
                tap(() => {
                    this.sendingFeedback = true;
                }),
                switchMap(portalId =>
                    this.hubspotService.createMessage(portalId, data)
                ),
                tap(() => {
                    this.sendingFeedback = false;
                    this.formSent = true;
                }),
                catchError(e => {
                    console.log("Sending feedback error:", e);
                    this.sendingFeedback = false;
                    this.toasterService.info(
                        "Cannot create feedback. Contact harvestr for more information"
                    );
                    return EMPTY;
                })
            )
            .subscribe();
    }

    backToForm() {
        this._initDefaultValues$()
            .pipe(
                first(),
                tap(() => {
                    this.formSent = false;
                })
            )
            .subscribe();
    }
}
