import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { HxBgColorDirective } from "./directives/hx-bg-color.directive";
import { HxColorDirective } from "./directives/hx-color.directive";
import { HxTagColorDirective } from "./directives/hx-tag-color.directive";

@NgModule({
    imports: [CommonModule],
    declarations: [HxBgColorDirective, HxColorDirective, HxTagColorDirective],
    exports: [HxBgColorDirective, HxColorDirective, HxTagColorDirective]
})
export class DisplayConverterModule {}
