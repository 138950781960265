import { Seat_Type } from "@harvestr-client/shared/model/api";

export const personSeatTypeToDisplay = (seatType: Seat_Type) => {
    switch (seatType) {
        case "PRIMARY_OWNER":
        case "OWNER":
            return "Editor";
        default:
            return "Contributor";
    }
};
