import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { AppRoutes } from "./app.routing";
import { HubspotModule } from "./hubspot/hubspot.module";

@NgModule({
    imports: [AppRoutes, HubspotModule],
    declarations: [AppComponent],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {}
