import {
    Color,
    Person_Type,
    Seat_Type
} from "@harvestr-client/shared/model/api";
import { ProfilePictureData } from "./profile-picture.model";

interface _HxMenuElBase<T> {
    id: string;
    value: string;
    secondaryValue?: string; // ? displayed inline next to value as secondary text
    color?: Color;
    separation?: boolean;
    partial?: boolean;
    disable?: boolean;
    icon?: {
        name: string;
        color?: Color;
    };
    tooltip?: string;
    el?: T;
}

export interface PersonHxMenuEl<T> extends _HxMenuElBase<T> {
    type: "PERSON";
    pictureData: ProfilePictureData;
}

export interface CompanyHxMenuEl<T> extends _HxMenuElBase<T> {
    type: "COMPANY";
}

export interface DefaultHxMenuEl<T> extends _HxMenuElBase<T> {
    type: "DEFAULT";
}

export type HxMenuEl<T> =
    | PersonHxMenuEl<T>
    | CompanyHxMenuEl<T>
    | DefaultHxMenuEl<T>;

export interface HxMenuGroup<T> {
    id?: string;
    value?: string;
    els?: HxMenuEl<T>[];
}

export type HxMenuLineType = "PERSON" | "COMPANY" | "DEFAULT";
export type DisplayHxMenuLineType = "CREATE" | "ADD";

export const EMPTY_EL_ID = "EMPTY";
export const NONE_EL_ID = "NONE";

export interface CollaboratorInMenuEl {
    id: string;
    type: Person_Type;
    seatType: Seat_Type;
    email: string | null;
    name: string;
    avatarUrl: string | null;
}
