import { InjectionToken } from "@angular/core";
import { HfcDefaults } from "./hfc-defaults.model";

/**
 * @const ENVIRONMENT
 * Injection token for the hfc environment interface to be provided by the applications.
 */
export const HFC_DEFAULTS: InjectionToken<HfcDefaults> = new InjectionToken(
    "HFC_DEFAULTS"
);
