import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import {
    CompanyEl,
    ContactEl,
    Deal,
    MessageLabelFlatFragment,
    TokenOptions
} from "../../models";
import { HubspotApiFeedback } from "../models/feedbacks.model";

@Injectable()
export class HubspotService {
    constructor(private http: HttpClient) {}

    private _getHeaders(portalId: string) {
        return {
            headers: { Authorization: "Bearer " + portalId }
        };
    }

    refreshToken(portalId: string) {
        return this.http.get<{ ok: boolean }>(
            environment.api_url + `/authorize/hubspot/refresh-token`,
            this._getHeaders(portalId)
        );
    }

    getMessageLabels(portalId: string): Observable<MessageLabelFlatFragment[]> {
        return this.http
            .get<
                MessageLabelFlatFragment[]
            >(environment.api_url + `/hubspot/labels`, this._getHeaders(portalId))
            .pipe(catchError((error: any) => throwError(this._pError(error))));
    }

    getCompanies(portalId: string, q: string): Observable<CompanyEl[]> {
        return this.http
            .get<
                CompanyEl[]
            >(environment.api_url + `/hubspot/companies?q=` + q || "", this._getHeaders(portalId))
            .pipe(catchError((error: any) => throwError(this._pError(error))));
    }

    getContacts(portalId: string, name: string): Observable<ContactEl[]> {
        return this.http
            .get<
                ContactEl[]
            >(environment.api_url + `/hubspot/contacts?name=` + name || "", this._getHeaders(portalId))
            .pipe(catchError((error: any) => throwError(this._pError(error))));
    }

    getComanyContacts(
        portalId: string,
        companyId: string
    ): Observable<ContactEl[]> {
        return this.http
            .get<
                ContactEl[]
            >(environment.api_url + `/hubspot/companies/${companyId}/contacts`, this._getHeaders(portalId))
            .pipe(catchError((error: any) => throwError(this._pError(error))));
    }

    getDeals(portalId: string, name?: string): Observable<Deal[]> {
        return this.http
            .get<Deal[]>(environment.api_url + `/hubspot/deals`, {
                headers: this._getHeaders(portalId).headers,
                params: { name }
            })
            .pipe(catchError((error: any) => throwError(this._pError(error))));
    }

    getDealById(portalId: string, dealId: string): Observable<Deal> {
        return this.http
            .get<Deal>(environment.api_url + `/hubspot/deals/${dealId}`, {
                headers: this._getHeaders(portalId).headers
            })
            .pipe(catchError((error: any) => throwError(this._pError(error))));
    }

    getTokenOptions(portalId: string): Observable<TokenOptions> {
        return this.http
            .get<TokenOptions>(
                environment.api_url + `/hubspot/token`,
                this._getHeaders(portalId)
            )
            .pipe(catchError((error: any) => throwError(this._pError(error))));
    }

    createMessage(portalId: string, data: any): Observable<any> {
        return this.http
            .post(
                environment.api_url + `/hubspot/message`,
                data,
                this._getHeaders(portalId)
            )
            .pipe(catchError((error: any) => throwError(this._pError(error))));
    }

    fetchFeedbacks$(
        portalId: string,
        payload: {
            associatedObjectType: string;
            name: string;
            email: string;
            hs_object_id: string;
        }
    ): Observable<HubspotApiFeedback[]> {
        return this.http
            .get<HubspotApiFeedback[]>(
                environment.api_url + `/hubspot/feedbackList`,
                {
                    headers: this._getHeaders(portalId).headers,
                    params: payload
                }
            )
            .pipe(catchError((error: any) => throwError(this._pError(error))));
    }

    private _pError(error: { error?: string }) {
        return error.error && typeof error.error === "string"
            ? error.error
            : "Could not connect to Harvestr";
    }
}
