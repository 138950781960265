import { CommonModule } from "@angular/common";
import {
    HTTP_INTERCEPTORS,
    provideHttpClient,
    withInterceptorsFromDi
} from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppHubspotWidgetDataAccessApiRestHxModule } from "@harvestr-client/app-hubspot-widget/data-access/api-rest-hx";
import { SharedNgUiCommonModule } from "@harvestr-client/shared/ng/ui-common";
import { SharedNgUiFeedbackCardModule } from "@harvestr-client/shared/ng/ui-feedback-card";
import { HxMenuModule } from "@harvestr-client/shared/ng/ui-hx-menu";
import { HxWysiwygModule } from "@harvestr-client/shared/ng/ui-hx-wysiwyg";
import { SharedUiToasterModule } from "@harvestr-client/shared/ng/ui-overlay-alert-toaster";
import { HUBSPOT_ENV_ENDPOINTS } from "@harvestr-client/shared/ng/util-injector-environment";
import { environment } from "../../environments/environment";
import { CdkModule } from "../shared/cdk.module";
import { HubspotParamsCheck } from "./guards/hubspot-params-check.guard";
import { HubspotFormComponent } from "./hubspot-form/hubspot-form.component";
import { HubspotListComponent } from "./hubspot-list/hubspot-list.component";
import { HubspotRoutes } from "./hubspot.routing";
import { HubspotWorkspaceIdInterceptor } from "./interceptors/workspace-id.interceptor";
import { NonHubspotRedirectComponent } from "./non-hubspot-redirect/non-hubspot-redirect.component";
import { HubspotService } from "./services/hubspot.service";

@NgModule({
    declarations: [
        HubspotFormComponent,
        NonHubspotRedirectComponent,
        HubspotListComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedUiToasterModule,
        HxWysiwygModule,
        BrowserModule,
        BrowserAnimationsModule,
        CdkModule,
        HxMenuModule,
        AppHubspotWidgetDataAccessApiRestHxModule,
        HubspotRoutes,
        SharedNgUiFeedbackCardModule,
        SharedNgUiCommonModule
    ],
    providers: [
        HubspotParamsCheck,
        HubspotService,
        {
            provide: HUBSPOT_ENV_ENDPOINTS,
            useValue: environment
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HubspotWorkspaceIdInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class HubspotModule {}
