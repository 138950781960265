import { Seat_Type } from "@harvestr-client/shared/model/api";

export const seatTypeIsEditor = (seatType: Seat_Type | null | undefined) => {
    switch (seatType) {
        case Seat_Type.Owner:
        case Seat_Type.PrimaryOwner:
            return true;
        default:
            return false;
    }
};
