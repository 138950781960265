import { InjectionToken } from "@angular/core";
import { EnvEndpoints } from "./environment.model";

/**
 * @const ENV_ENDPOINTS
 * Injection token for the environment interface to be provided by the applications.
 */
export const ENV_ENDPOINTS: InjectionToken<EnvEndpoints> = new InjectionToken(
    "ENV_ENDPOINTS"
);
