import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
    {
        path: "",
        loadChildren: () =>
            import("./hubspot/hubspot.module").then(m => m.HubspotModule)
    },
    { path: "**", redirectTo: "/404" }
];

export const AppRoutes = RouterModule.forRoot(routes);
