import { OverlayModule } from "@angular/cdk/overlay";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SharedNgUtilNgModule } from "@harvestr-client/shared/ng/util-ng";
import { ChunkScorerComponent } from "./_components/chunk-scorer/chunk-scorer.component";
import { CollaboratorInlineComponent } from "./_components/collaborator-inline/collaborator-inline.component";
import { ColorListPickerComponent } from "./_components/color-list-picker/color-list-picker.component";
import { DiscoverystateTagComponent } from "./_components/discoverystate-tag/discoverystate-tag.component";
import { FeedbackScoreSelectorComponent } from "./_components/feedback-score-selector/feedback-score-selector.component";
import { HxBtnHolderComponent } from "./_components/hx-btn-container/btn-holder.component";
import { HxCardComponent } from "./_components/hx-card/hx-card.component";
import { HxSidebarTitleComponent } from "./_components/hx-sidebar/components/sidebar-title/sidebar-title.component";
import { HxSidebarComponent } from "./_components/hx-sidebar/sidebar.component";
import { HxSwitchComponent } from "./_components/hx-switch/hx-switch.component";
import { HxToggleComponent } from "./_components/hx-toggle/hx-toggle.component";
import { IconArrowComponent } from "./_components/icon-arrow/icon-arrow.component";
import { InlinePersonComponent } from "./_components/inline-person/inline-person.component";
import { MessageChannelIconComponent } from "./_components/message-channel-icon/message-channel-icon.component";
import { MessageLabelItemComponent } from "./_components/message-label-item/message-label-item.component";
import { ProfilePictureComponent } from "./_components/profile-picture/profile-picture.component";
import { ProjectPictureComponent } from "./_components/project-picture/project-picture.component";
import { RatingComponent } from "./_components/rating/rating.component";
import { ReactionsDisplayComponent } from "./_components/reactions-display/reactions-display.component";
import { SpinnerComponent } from "./_components/spinner/spinner.component";
import { SpinnerInlineComponent } from "./_components/spinner-inline/spinner-inline.component";
import { SpinnerInputComponent } from "./_components/spinner-input/spinner-input.component";
import { StateProgressbarComponent } from "./_components/state-progressbar/state-progressbar.component";
import { SwitchComponent } from "./_components/switch/switch.component";
import { UnionTagsHolderComponent } from "./_components/union-tags-holder/union-tags-holder.component";
import { InlinePersonMenuHandlerDirective } from "./_directives/inline-person-menu-handler.directive";
import { ModalConfirmComponent } from "./_modals/modal-confirm/modal-confirm.component";

@NgModule({
    imports: [
        CommonModule,
        MatTooltipModule,
        // DialogModule,
        ReactiveFormsModule,
        FormsModule,
        OverlayModule,
        SharedNgUtilNgModule
    ],
    declarations: [
        ModalConfirmComponent,
        SpinnerComponent,
        SpinnerInputComponent,
        ProfilePictureComponent,
        HxBtnHolderComponent,
        HxSidebarComponent,
        HxSidebarTitleComponent,
        UnionTagsHolderComponent,
        HxCardComponent,
        HxToggleComponent,
        HxSwitchComponent,
        StateProgressbarComponent,
        ReactionsDisplayComponent,
        RatingComponent,
        ProjectPictureComponent,
        DiscoverystateTagComponent,
        ChunkScorerComponent,
        MessageLabelItemComponent,
        ColorListPickerComponent,
        IconArrowComponent,
        SwitchComponent,
        InlinePersonComponent,
        CollaboratorInlineComponent,
        InlinePersonMenuHandlerDirective,
        SpinnerInlineComponent,
        FeedbackScoreSelectorComponent,
        MessageChannelIconComponent
    ],
    exports: [
        ModalConfirmComponent,
        SpinnerComponent,
        SpinnerInlineComponent,
        SpinnerInputComponent,
        ProfilePictureComponent,
        HxBtnHolderComponent,
        HxSidebarComponent,
        HxSidebarTitleComponent,
        UnionTagsHolderComponent,
        HxCardComponent,
        HxToggleComponent,
        HxSwitchComponent,
        StateProgressbarComponent,
        ReactionsDisplayComponent,
        RatingComponent,
        ProjectPictureComponent,
        DiscoverystateTagComponent,
        ChunkScorerComponent,
        MessageLabelItemComponent,
        ColorListPickerComponent,
        IconArrowComponent,
        SwitchComponent,
        InlinePersonComponent,
        CollaboratorInlineComponent,
        InlinePersonMenuHandlerDirective,
        FeedbackScoreSelectorComponent,
        MessageChannelIconComponent
    ]
})
export class SharedNgUiCommonModule {}
