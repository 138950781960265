import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ApiAtachmentPresignReponse } from "@harvestr-client/shared/model/api";
import {
    HUBSPOT_ENV_ENDPOINTS,
    HubspotEnvEndpoints,
    ImageUploadAbstractServicePorts
} from "@harvestr-client/shared/ng/util-injector-environment";

@Injectable()
export class HubspotImageUploadService extends ImageUploadAbstractServicePorts {
    private readonly _presignEndpoint = `${this.environment.api_url}/hubspot/signed-attachment`;

    override _fetchAttachmentSignedUrl$(file: { name: string; type: string }) {
        return this.http.get<ApiAtachmentPresignReponse>(
            this._presignEndpoint,
            {
                params: {
                    fileName: file.name,
                    fileMimeType: file.type
                }
            }
        );
    }

    constructor(
        @Inject(HUBSPOT_ENV_ENDPOINTS)
        private environment: Pick<HubspotEnvEndpoints, "api_url">,
        http: HttpClient
    ) {
        super(http);
    }
}
