<div class="feedback-list">
    <label class="bold">Feedback: {{ feedbackList.length }}</label>
    <div *ngFor="let feedbackData of feedbackList; trackBy: trackById">
        <hfc-feedback-card
            (click)="onFeedbackClick(feedbackData)"
            [data]="feedbackData"
            class="pointer"
        >
            <div feedbackCardIcon>
                <hx-message-channel-icon
                    feedbackCardIcon
                    [channel]="feedbackData.channel"
                >
                </hx-message-channel-icon>
            </div>
            <div feedbackReadableState>
                <label>{{ feedbackData.status }}</label>
            </div>
        </hfc-feedback-card>
    </div>
</div>
