import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    SimpleChanges
} from "@angular/core";
import { Channel } from "@harvestr-client/shared/model/api";
import { convertChannelToClass } from "@harvestr-client/shared/shared/util-helper";

@Component({
    selector: "hx-message-channel-icon",
    templateUrl: "./message-channel-icon.component.html",
    styleUrls: ["./message-channel-icon.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageChannelIconComponent implements OnChanges {
    @Input({ required: true }) channel: Channel | undefined;

    iconName: string | undefined;

    ngOnChanges(changes: SimpleChanges) {
        if (changes["channel"]) {
            this.iconName = convertChannelToClass(this.channel);
        }
    }
}
