import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class LoadingToasterService {
    private subject = new Subject<{ type: "SHOW" } | false>();

    get loadingToaster$() {
        return this.subject.asObservable();
    }

    show() {
        this.subject.next({ type: "SHOW" });
    }

    hide() {
        this.subject.next(false);
    }
}
