import {
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { map, switchMap } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({ providedIn: "root" })
export class HubspotWorkspaceIdInterceptor implements HttpInterceptor {
    constructor(private route: ActivatedRoute) {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler) {
        const apiUrlReg = new RegExp(environment.api_url);
        if (!apiUrlReg.test(req.url)) {
            return next.handle(req);
        }

        const fetchPortalId$ = this.route.queryParamMap.pipe(
            map(query => query.get("portalId"))
        );

        return fetchPortalId$.pipe(
            switchMap(portalId => {
                if (!portalId) {
                    return next.handle(req);
                }

                const newReq = req.clone({
                    setHeaders: {
                        Authorization: "Bearer " + portalId
                    }
                });
                return next.handle(newReq);
            })
        );
    }
}
