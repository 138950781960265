import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SharedNgUiCommonModule } from "@harvestr-client/shared/ng/ui-common";
import { DisplayConverterModule } from "@harvestr-client/shared/ng/util-display-converter";
import { HxMenuInputDirective } from "./_directives/hx-menu-input.directive";
import { HxMenuLineDirective } from "./_directives/hx-menu-line.directive";
import { HxMenuDirective } from "./_directives/hx-menu.directive";
import { HxMenuComponent } from "./hx-menu.component";

@NgModule({
    imports: [
        ScrollingModule,
        CommonModule,
        SharedNgUiCommonModule,
        FormsModule,
        DisplayConverterModule,
        MatTooltipModule
    ],
    declarations: [
        HxMenuComponent,
        HxMenuLineDirective,
        HxMenuDirective,
        HxMenuInputDirective
    ],
    exports: [HxMenuComponent, HxMenuDirective]
})
export class HxMenuModule {}
