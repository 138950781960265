import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatMenuModule } from "@angular/material/menu";
import { SharedNgUiCommonModule } from "@harvestr-client/shared/ng/ui-common";
import { DisplayConverterModule } from "@harvestr-client/shared/ng/util-display-converter";
import { NgxTiptapModule } from "ngx-tiptap";
import { ClickableDescriptionDirective } from "./_directives/clickable-description.directive";
import { WysiwyUploadImagesDirective } from "./_directives/wysiwyg-clipboard-upload.directive";
import { WysiwyEditionShortcutsDirective } from "./_directives/wysiwyg-edition-shortcuts.directive";
import { HxWysiwygComponent } from "./hx-wysiwyg/hx-wysiwyg.component";
import { MentionContainerComponent } from "./mention-container/mention-container.component";
import { SlashcommandsContainerComponent } from "./slashcommands-container/slashcommands-container.component";

@NgModule({
    imports: [
        CommonModule,
        SharedNgUiCommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxTiptapModule,
        MatMenuModule,
        DisplayConverterModule
    ],
    declarations: [
        MentionContainerComponent,
        SlashcommandsContainerComponent,
        HxWysiwygComponent,
        WysiwyEditionShortcutsDirective,
        WysiwyUploadImagesDirective,
        ClickableDescriptionDirective
    ],
    exports: [
        HxWysiwygComponent,
        WysiwyUploadImagesDirective,
        WysiwyEditionShortcutsDirective,
        ClickableDescriptionDirective
    ]
})
export class HxWysiwygModule {}
