<ng-container *ngIf="!formSent; else sent">
    <div class="hx-form-field">
        <label for="title" class="required">Title</label>
        <input
            [formControl]="titleControl"
            id="title"
            type="text"
            class="hx-input"
        />
    </div>

    <div class="hx-form-field">
        <label class="required">Requester type</label>
        <div
            class="mat-menu-trigger hx-btn menu-toggle"
            #tRequesterMenu="matMenuTrigger"
            [matMenuTriggerFor]="requesterMenu"
        >
            {{ requesterType.value || "None" }}
        </div>
        <mat-menu #requesterMenu="matMenu">
            <hx-menu
                *ngIf="tRequesterMenu.menuOpen"
                [data]="requesterTypeEls"
                [selected]="requesterType"
                [showSelected]="true"
                (selectedChange)="
                    setRequesterType($event); tRequesterMenu.closeMenu()
                "
                width="SMALL"
            ></hx-menu>
        </mat-menu>
    </div>

    <div class="hx-form-field" *ngIf="requesterType?.el === 'CONTACT'">
        <label class="hx-color-grey required">Contact name</label>
        <div
            class="mat-menu-trigger hx-btn menu-toggle"
            #tPersonsMenu="matMenuTrigger"
            [matMenuTriggerFor]="personsMenu"
        >
            {{
                contactControl?.value?.el?.name ||
                    contactControl?.value?.el?.email ||
                    "None"
            }}
        </div>
        <mat-menu #personsMenu="matMenu">
            <hx-menu
                *ngIf="tPersonsMenu.menuOpen"
                [data]="contactEls"
                [formControl]="contactControl"
                [searchable]="true"
                [showSelected]="true"
                [externalSearch]="true"
                (queryChange)="onSearchContacts($event)"
                [(query)]="contactQuery"
                (selectedChange)="tPersonsMenu.closeMenu()"
                width="SMALL"
                placeholder="Search for a contact..."
            ></hx-menu>
        </mat-menu>
    </div>
    <div *ngIf="requesterType?.el === 'COMPANY'">
        <div class="hx-form-field">
            <label class="hx-color-grey required">Company name</label>
            <div
                class="mat-menu-trigger hx-btn menu-toggle"
                #tCompaniesMenu="matMenuTrigger"
                [matMenuTriggerFor]="companiesMenu"
            >
                {{ companyControl?.value?.el?.name || "None" }}
            </div>
            <mat-menu #companiesMenu="matMenu">
                <hx-menu
                    *ngIf="tCompaniesMenu.menuOpen"
                    [data]="companyEls"
                    [formControl]="companyControl"
                    [searchable]="true"
                    [showSelected]="true"
                    (queryChange)="onSearchCompanies($event)"
                    [(query)]="companyQuery"
                    (selectedChange)="tCompaniesMenu.closeMenu()"
                    width="SMALL"
                    placeholder="Search for a company..."
                ></hx-menu>
            </mat-menu>
        </div>
        <div
            class="hx-form-field"
            *ngIf="companyControl?.value?.el && companyContactEls?.length > 1"
        >
            <label class="hx-color-grey">Contact</label>
            <div
                class="mat-menu-trigger hx-btn menu-toggle"
                #tPersonsMenu="matMenuTrigger"
                [matMenuTriggerFor]="personsMenu"
            >
                {{
                    companyContactControl?.value?.el?.name ||
                        companyContactControl?.value?.el?.email ||
                        "None"
                }}
            </div>
            <mat-menu #personsMenu="matMenu">
                <hx-menu
                    *ngIf="tPersonsMenu.menuOpen"
                    [data]="companyContactEls"
                    [formControl]="companyContactControl"
                    name="contactContact"
                    [searchable]="true"
                    [showSelected]="true"
                    (selectedChange)="tPersonsMenu.closeMenu()"
                    width="SMALL"
                    placeholder="Search for a contact..."
                ></hx-menu>
            </mat-menu>
        </div>
    </div>

    <div class="hx-form-field" *ngIf="requesterType?.el === 'DEAL_CONTACT'">
        <label class="hx-color-grey required">Contact name</label>
        <div
            class="mat-menu-trigger hx-btn menu-toggle"
            #tDealPersonsMenu="matMenuTrigger"
            [matMenuTriggerFor]="dealPersonsMenu"
        >
            {{
                dealContactControl?.value?.el?.name ||
                    dealContactControl?.value?.el?.email ||
                    "None"
            }}
        </div>
        <mat-menu #dealPersonsMenu="matMenu">
            <hx-menu
                *ngIf="tDealPersonsMenu.menuOpen"
                [data]="dealContactsEls"
                [formControl]="dealContactControl"
                [searchable]="true"
                [showSelected]="true"
                [(query)]="contactQuery"
                (selectedChange)="tDealPersonsMenu.closeMenu()"
                width="SMALL"
                placeholder="Search for a contact..."
            ></hx-menu>
        </mat-menu>
    </div>

    <div *ngIf="requesterType?.el === 'DEAL_COMPANY'">
        <div class="hx-form-field">
            <label class="hx-color-grey required">Company name</label>
            <div
                class="mat-menu-trigger hx-btn menu-toggle"
                #tDealCompaniesMenu="matMenuTrigger"
                [matMenuTriggerFor]="dealCompaniesMenu"
            >
                {{ dealCompanyControl?.value?.el?.name || "None" }}
            </div>
            <mat-menu #dealCompaniesMenu="matMenu">
                <hx-menu
                    *ngIf="tDealCompaniesMenu.menuOpen"
                    [data]="dealCompaniesEls"
                    [formControl]="dealCompanyControl"
                    [searchable]="true"
                    [showSelected]="true"
                    [(query)]="companyQuery"
                    (selectedChange)="tDealCompaniesMenu.closeMenu()"
                    width="SMALL"
                    placeholder="Search for a company..."
                ></hx-menu>
            </mat-menu>
        </div>
        <div
            class="hx-form-field"
            *ngIf="
                dealCompanyControl?.value?.el &&
                dealCompanyContactEls?.length > 1
            "
        >
            <label class="hx-color-grey">Contact</label>
            <div
                class="mat-menu-trigger hx-btn menu-toggle"
                #tDealCompanyContactMenu="matMenuTrigger"
                [matMenuTriggerFor]="dealCompanyContactMenu"
            >
                {{
                    dealCompanyContactControl?.value?.el?.name ||
                        dealCompanyContactControl?.value?.el?.email ||
                        "None"
                }}
            </div>
            <mat-menu #dealCompanyContactMenu="matMenu">
                <hx-menu
                    *ngIf="tDealCompanyContactMenu.menuOpen"
                    [data]="dealCompanyContactEls"
                    [formControl]="dealCompanyContactControl"
                    name="dealCompanyContact"
                    [searchable]="true"
                    [showSelected]="true"
                    (selectedChange)="tDealCompanyContactMenu.closeMenu()"
                    width="SMALL"
                    placeholder="Search for a contact..."
                ></hx-menu>
            </mat-menu>
        </div>
    </div>

    <div class="hx-form-field">
        <label>Labels</label>
        <div class="hx-tags-holder">
            <div
                class="hx-tag white-tag tag-close"
                *ngFor="let label of labelsControl?.value"
            >
                {{ label.value }}
                <i class="icon-x" (click)="removeLabelFromForm(label)"></i>
            </div>
            <div
                class="hx-tag white-tag clickable-part"
                #tLabelsMenu="matMenuTrigger"
                [matMenuTriggerFor]="labelsMenu"
            >
                <i class="icon-plus"></i> Add label
            </div>
            <mat-menu #labelsMenu="matMenu">
                <hx-menu
                    *ngIf="tLabelsMenu.menuOpen"
                    [data]="messageLabelsEls"
                    [multiselect]="true"
                    [formControl]="labelsControl"
                    [searchable]="true"
                    width="SMALL"
                    [showSelected]="true"
                    placeholder="Search for labels..."
                ></hx-menu>
            </mat-menu>
        </div>
    </div>

    <div class="hx-form-field">
        <label for="content">Content</label>
        <hx-wysiwyg
            class="message-content w-100"
            id="content"
            mode="HUBSPOT"
            [hxWysiwygUploadImages]
            [contentControl]="contentControl"
        >
        </hx-wysiwyg>
    </div>

    <button
        type="submit"
        class="hx-btn btn-primary"
        [disabled]="form.invalid || sendingFeedback"
        (click)="send()"
    >
        Send
    </button>
</ng-container>
<ng-template #sent>
    <div class="done-container">
        <div class="title">Successfully sent to Harvestr</div>
        <div class="subtitle">
            Refresh your page to seed your feedback in the "Harvestr Product
            Feedback" section
        </div>
        <span class="hx-btn btn-primary" (click)="backToForm()">
            New feedback
        </span>
    </div>
</ng-template>

<hx-toaster-container></hx-toaster-container>
