import { AfterViewInit, Directive, ElementRef, Input } from "@angular/core";

@Directive({
    selector: "[hxMenuInput]"
})
export class HxMenuInputDirective implements AfterViewInit {
    @Input({ required: true }) hxMenuInput: undefined;

    constructor(public el: ElementRef) {}

    ngAfterViewInit(): void {
        this.el.nativeElement.focus();
    }
}
