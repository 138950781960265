export type SupportedPreviewAttachmentType =
    | "IMAGE"
    | "AUDIO"
    | "VIDEO"
    | "PDF"
    | "OTHER";

export const imageTypes = [
    "image/gif",
    "image/jpeg",
    "image/png",
    "image/bmp",
    "image/x-icon"
];

export const videoTypes = [
    "video/quicktime",
    "video/mp4",
    "video/mpeg",
    "video/ogg",
    "video/webm",
    "video/3gpp"
];
export const pdfTypes = ["application/pdf"];
export const audioTypes = [
    "audio/3gpp",
    "audio/wav",
    "audio/webm",
    "audio/mpeg",
    "audio/mp3",
    "audio/ogg",
    "audio/m4a",
    "audio/x-m4a"
];
export const otherTypes = [
    ...videoTypes,
    ...pdfTypes,
    ...audioTypes,
    "image/svg+xml",
    "application/zip",
    "text/csv",
    "text/css",
    "text/html",
    "text/plain",
    "application/x-bzip",
    "application/x-bzip2",
    "image/gif",
    "image/x-icon",
    "text/calendar",
    "application/java-archive",
    "image/jpeg",
    "application/json",
    "application/ogg",
    "font/otf",
    "application/x-rar-compressed",
    "application/rtf",
    "image/svg+xml",
    "application/x-shockwave-flash",
    "application/x-tar",
    "image/tiff",
    "font/ttf",
    "image/webp",
    "font/woff",
    "font/woff2",
    "application/xhtml+xml",
    "application/xml",
    "application/vnd.mozilla.xul+xml",
    "application/x-7z-compressed",
    "application/msword", // .doc
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
    "application/vnd.ms-powerpoint", // .ppt
    "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx
    "application/vnd.ms-excel", // .xls
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" // .xlsx
];
