import { HxMenuEl } from "@harvestr-client/shared/model/app";
import { searchHelper } from "@harvestr-client/shared/shared/util-helper";
import { Editor, Extension, Range } from "@tiptap/core";
import Suggestion, { SuggestionOptions } from "@tiptap/suggestion";

export type EditorSlashCommand = "LINK_FIGMA" | "LINK_ATTACHMENT";

export interface EditorSlashCommandElData {
    id: EditorSlashCommand;
    command: (c: { editor: Editor; range: Range }) => void;
}

export const slashCommandsListEls: HxMenuEl<EditorSlashCommandElData>[] = [
    {
        id: "LINK_FIGMA",
        type: "DEFAULT",
        value: "Link Figma",
        icon: { name: "iconhx-figma" },
        el: {
            id: "LINK_FIGMA",
            command: ({ editor, range }) => {
                editor.chain().focus().deleteRange(range).run();
            }
        }
    },
    {
        id: "LINK_ATTACHMENT",
        type: "DEFAULT",
        value: "Add Attachment",
        icon: { name: "icon-paperclip" },
        el: {
            id: "LINK_ATTACHMENT",
            command: ({ editor, range }) => {
                editor.chain().focus().deleteRange(range).run();
            }
        }
    }
];

export function buildSlashCommandsMenuItemsList(props: {
    query: string;
    editor: Editor;
}) {
    const query = props.query;
    const regexp = searchHelper.getSearchRegex(query || "");
    // ? search query filter
    return (slashCommandsListEls || [])
        .filter(val => {
            return searchHelper.filterByQuery({
                regexp,
                searchable: [val.value || ""]
            });
        })
        .slice(0, 50);
}

export const SlashCommandsCustomExtension = Extension.create<{
    suggestion: Omit<
        SuggestionOptions<HxMenuEl<EditorSlashCommandElData>>,
        "editor"
    >;
}>({
    name: "commands",

    addOptions() {
        return {
            suggestion: {
                char: "/",
                command: d => {
                    const { editor, range, props } = d;
                    if (!props?.el) {
                        return;
                    }
                    props.el.command({ editor, range });
                }
            }
        };
    },

    addProseMirrorPlugins() {
        return [
            Suggestion({
                editor: this.editor,
                ...this.options.suggestion
            })
        ];
    }
});
