import {
    Channel,
    Message_Label_Source
} from "@harvestr-client/shared/model/api";

export const convertMessageLabelSourceAccessToMessageChannel = (
    source: Message_Label_Source
): Channel => {
    switch (source) {
        case Message_Label_Source.Freshdesk:
            return Channel.Freshdesk;
        case Message_Label_Source.Intercom:
            return Channel.Intercom;
        case Message_Label_Source.Salesforce:
            return Channel.Salesforce;
        case Message_Label_Source.Zendesk:
            return Channel.Zendesk;
    }
};

export const convertChannelToClass = (channel: Channel | undefined): string => {
    switch (channel) {
        case Channel.Note:
            return "icon-file";
        case Channel.Mail:
            return "icon-mail";
        case Channel.Slack:
            return "iconhx-slack";
        case Channel.Sheet:
            return "icon-brand12";
        case Channel.Zendesk:
            return "icon-brand17";
        case Channel.Salesforce:
            return "iconhx-salesforce";
        case Channel.Intercom:
            return "icon-brand10";
        case Channel.Form:
            return "icon-message-circle";
        case Channel.Freshdesk:
            return "iconhx-freshdesk";
        case Channel.ChromeWidget:
            return "iconhx-chrome";
        case Channel.Hubspot:
            return "iconhx-hubspot";
        case Channel.Hfc:
            return "channel iconhx8-suggest-3";
        default:
            return "";
        // case Channel.Jira:
        //     return "iconhx-jira";
    }
};
