import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Channel } from "@harvestr-client/shared/model/api";

export interface FeedbackCardData {
    id: string;
    channel: Channel;
    title: string;
    caption: string;
    createdAt: string;
}

@Component({
    selector: "hfc-feedback-card",
    templateUrl: "./feedback-card.component.html",
    styleUrls: ["./feedback-card.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedbackCardComponent {
    @Input({ required: true }) data: FeedbackCardData | undefined;
}
