import { RouterModule, Routes } from "@angular/router";
import { HubspotParamsCheck } from "./guards/hubspot-params-check.guard";
import { HubspotFormComponent } from "./hubspot-form/hubspot-form.component";
import { HubspotListComponent } from "./hubspot-list/hubspot-list.component";
import { NonHubspotRedirectComponent } from "./non-hubspot-redirect/non-hubspot-redirect.component";

const routes: Routes = [
    {
        path: "feedback",
        // canActivate: [HubspotParamsCheck],
        children: [
            {
                path: "form",
                component: HubspotFormComponent
                // canActivate: [HubspotParamsCheck]
            },
            {
                path: "list",
                component: HubspotListComponent,
                canActivate: [HubspotParamsCheck]
            }
        ]
    },
    { path: "**", component: NonHubspotRedirectComponent }
];

export const HubspotRoutes = RouterModule.forChild(routes);
