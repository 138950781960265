<ng-container *ngIf="person?.id; else elseEmptyPerson">
    <div
        *ngIf="person?.avatarUrl; else elseNoAvatarUrl"
        class="user-picture as-image"
        [class]="pictureSizeClass"
        [style.background-image]="'url(' + person?.avatarUrl + ')'"
        matTooltipShowDelay="300"
        matTooltipPosition="below"
        [matTooltip]="tooltip"
    ></div>
    <ng-template #elseNoAvatarUrl>
        <div
            class="user-picture"
            [class]="pictureSizeClass"
            [class.admin-picture]="person?.type === 'COLLABORATOR'"
            matTooltipShowDelay="300"
            matTooltipPosition="below"
            [matTooltip]="tooltip"
        >
            <ng-container
                *ngIf="
                    person?.type !== 'ORGANIZATION_DEFAULT';
                    else elseOrgDefault
                "
            >
                {{ substringName }}
            </ng-container>
            <ng-template #elseOrgDefault>
                <i class="icon-briefcase"> </i
            ></ng-template>
        </div>
    </ng-template>
</ng-container>
<ng-template #elseEmptyPerson>
    <div class="user-picture empty" [class]="pictureSizeClass">
        <i class="icon-user"></i>
    </div>
</ng-template>
