import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input
} from "@angular/core";
import { HxMenuEl } from "@harvestr-client/shared/model/app";
import { CollaboratorInlineData } from "@harvestr-client/shared/ng/ui-common";
import { Subject } from "rxjs";
import { TipTapInjectedMentionsHack } from "../tiptap.models";

export interface MentionAsTagAttr {
    id: string;
    label: string;
}

export type MentionSelectItemData = MentionAsTagAttr;

@Component({
    templateUrl: "./mention-container.component.html",
    styleUrls: ["./mention-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MentionContainerComponent implements AfterContentInit {
    @Input() props:
        | TipTapInjectedMentionsHack<
              HxMenuEl<CollaboratorInlineData>,
              MentionSelectItemData
          >
        | undefined;

    selectedIndex = 0;

    init$$ = new Subject();

    ngAfterContentInit(): void {
        this.init$$.next(true);
        this.init$$.complete();
    }

    upHandler() {
        if (!this.props) {
            return;
        }
        this.selectedIndex =
            (this.selectedIndex + this.props.items.length - 1) %
            this.props.items.length;
        this.cd.markForCheck();
    }

    downHandler() {
        if (!this.props) {
            return;
        }
        this.selectedIndex = (this.selectedIndex + 1) % this.props.items.length;
        this.cd.markForCheck();
    }

    enterHandler() {
        this.selectItem(this.selectedIndex);
    }

    selectItem(index: number) {
        if (!this.props) {
            return;
        }
        const item = this.props.items[index];

        if (item?.el?.id) {
            // this.props.command(item);
            this.props.command({ id: item.el.id, label: item.value });
        }
    }

    onKeyDown({ event }: { event: { key: string } }) {
        if (event.key === "ArrowUp") {
            this.upHandler();
            return true;
        }
        if (event.key === "ArrowDown") {
            this.downHandler();
            return true;
        }
        if (event.key === "Enter") {
            this.enterHandler();
            return true;
        }
        return false;
    }

    markForCheck() {
        this.cd.markForCheck();
    }

    constructor(private cd: ChangeDetectorRef) {}
}
