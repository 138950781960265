import { Color } from "@harvestr-client/shared/model/api";

export const hxColorClassMap = {
    [""]: "",
    [Color.Black]: "hx-color-black",
    [Color.Grey]: "hx-color-grey",
    [Color.Red]: "hx-color-red",
    [Color.Orange]: "hx-color-orange",
    [Color.Yellow]: "hx-color-yellow",
    [Color.Lyellow]: "hx-color-lyellow",
    [Color.Lgreen]: "hx-color-lgreen",
    [Color.Green]: "hx-color-green",
    [Color.Lblue]: "hx-color-lblue",
    [Color.Blue]: "hx-color-blue",
    [Color.Azure]: "hx-color-azure",
    [Color.Lpurple]: "hx-color-lpurple",
    [Color.Purple]: "hx-color-purple",
    [Color.Lpink]: "hx-color-lpink",
    [Color.Pink]: "hx-color-pink"
};

export const hxColorHexMap = {
    [Color.Azure]: "#05c7c6",
    [Color.Blue]: "#42b2fc",
    [Color.Lblue]: "#6be0e7",
    [Color.Green]: "#5bc692",
    [Color.Lgreen]: "#98c141",
    [Color.Lyellow]: "#c7bb44",
    [Color.Yellow]: "#fdbd53",
    [Color.Orange]: "#fd8b4d",
    [Color.Red]: "#f96c68",
    [Color.Grey]: "#b0b0b0",
    [Color.Lpurple]: "#7856ff",
    [Color.Purple]: "#a64dff",
    [Color.Lpink]: "#d83dfe",
    [Color.Pink]: "#ff2fb9",
    [Color.Black]: "#323232"
};

export const hxBgColorClassMap = {
    [""]: "",
    [Color.Black]: "hx-bg-color-black",
    [Color.Grey]: "hx-bg-color-grey",
    [Color.Red]: "hx-bg-color-red",
    [Color.Orange]: "hx-bg-color-orange",
    [Color.Yellow]: "hx-bg-color-yellow",
    [Color.Lyellow]: "hx-bg-color-lyellow",
    [Color.Lgreen]: "hx-bg-color-lgreen",
    [Color.Green]: "hx-bg-color-green",
    [Color.Lblue]: "hx-bg-color-lblue",
    [Color.Blue]: "hx-bg-color-blue",
    [Color.Azure]: "hx-bg-color-azure",
    [Color.Lpurple]: "hx-bg-color-lpurple",
    [Color.Purple]: "hx-bg-color-purple",
    [Color.Lpink]: "hx-bg-color-lpink",
    [Color.Pink]: "hx-bg-color-pink"
};

export const colorToHxTagClass = (color: Color | undefined): string => {
    switch (color) {
        case Color.Red:
            return "tag-red";
        case Color.Orange:
            return "tag-orange";
        case Color.Yellow:
            return "tag-yellow";
        case Color.Lyellow:
            return "tag-lyellow";
        case Color.Lgreen:
            return "tag-lgreen";
        case Color.Green:
            return "tag-green";
        case Color.Lblue:
            return "tag-lblue";
        case Color.Blue:
            return "tag-blue";
        case Color.Azure:
            return "tag-azure";
        case Color.Lpurple:
            return "tag-lpurple";
        case Color.Purple:
            return "tag-purple";
        case Color.Lpink:
            return "tag-lpink";
        case Color.Pink:
            return "tag-pink";
        case Color.Grey:
            return "tag-grey";
        case Color.Black:
        default:
            return "";
    }
};
