<div *ngIf="data" class="feedback-card hiddenOnHover-parent">
    <div class="icon">
        <ng-content select="[feedbackCardIcon]"></ng-content>
    </div>
    <div class="title ellipsis">{{ data.title }}</div>
    <div class="caption ellipsis" [innerHtml]="data.caption | safeHtml"></div>
    <div class="status">
        <ng-content select="[feedbackReadableState]"></ng-content>
    </div>

    <div class="date">
        {{ data.createdAt | date: "MMM d" }}
    </div>
    <div class="arrow">
        <i class="icon-chevron-right hiddenOnHover-element"></i>
    </div>
</div>
