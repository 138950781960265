import { Directive, HostBinding, Input } from "@angular/core";
import { Color } from "@harvestr-client/shared/model/api";
import { colorToHxTagClass } from "../helpers/hx-colors.helper";

@Directive({
    selector: "[hxTagColor]"
})
export class HxTagColorDirective {
    @Input() set hxTagColor(v: Color | undefined) {
        this.elementClass = colorToHxTagClass(v);
    }
    @HostBinding("class") elementClass = colorToHxTagClass(Color.Grey);
}
