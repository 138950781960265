import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AutosizeDirective } from "./_directives/autosize.directive";
import { BlurOnEnterDirective } from "./_directives/blur-on-enter.directive";
import { DisableMatMenuDirective } from "./_directives/disable-mat-menu.directive";
import { FigmaDirective } from "./_directives/figma.directive";
import { FocusOnCreateDirective } from "./_directives/focus-on-create.directive";
import { FormControlErrorDisplayerDirective } from "./_directives/form-control-error-displayer.directive";
import { ResizableDirective } from "./_directives/resizable.directive";
import { ShowKeyboardShortcutDirective } from "./_directives/show-keyboard-shortcut.directive";
import { StopPropagationDirective } from "./_directives/stop-propagation.directive";
import { TargetBlankDirective } from "./_directives/target-blank.directive";
import { DateDifferencePipe } from "./_pipes/date-difference.pipe";
import { DisplayUrlPipe } from "./_pipes/display-url.pipe";
import { HighlightPipe } from "./_pipes/highlight.pipe";
import { OrderByPipe } from "./_pipes/orderby.pipe";
import { SafeHtmlPipe } from "./_pipes/safeHtml.pipe";
import { TimeAgoPipe } from "./_pipes/time-ago.pipe";

@NgModule({
    imports: [CommonModule],
    declarations: [
        AutosizeDirective,
        DisableMatMenuDirective,
        BlurOnEnterDirective,
        StopPropagationDirective,
        FigmaDirective,
        FocusOnCreateDirective,
        ShowKeyboardShortcutDirective,
        TimeAgoPipe,
        DateDifferencePipe,
        OrderByPipe,
        SafeHtmlPipe,
        HighlightPipe,
        DisplayUrlPipe,
        ResizableDirective,
        TargetBlankDirective,
        FormControlErrorDisplayerDirective
    ],
    exports: [
        AutosizeDirective,
        DisableMatMenuDirective,
        BlurOnEnterDirective,
        StopPropagationDirective,
        FigmaDirective,
        FocusOnCreateDirective,
        ShowKeyboardShortcutDirective,
        TimeAgoPipe,
        DateDifferencePipe,
        OrderByPipe,
        SafeHtmlPipe,
        HighlightPipe,
        DisplayUrlPipe,
        ResizableDirective,
        TargetBlankDirective,
        FormControlErrorDisplayerDirective
    ]
})
export class SharedNgUtilNgModule {}
