import { latinCharactersMap } from "./parse-helper-characters-map.helper";

export const escapeCharacterRegex = /[-[\]{}()*+?.,\\^$|#\s]/g;

const stringLatinize = (str: string) => {
    return (str || "").replace(
        /[^A-Za-z0-9[\] ]/g,
        a => latinCharactersMap[a] || a
    );
};

const getSearchRegex = (query: string) => {
    const split = (query || "").split(" ");
    const searchWords = split
        .map(partialWord => {
            const litinized = stringLatinize(partialWord);
            //https://github.com/tc39/proposal-regex-escaping
            const sanitized = litinized.replace(escapeCharacterRegex, "\\$&");
            return `(?=.*${sanitized})`;
        })
        .join("");
    const regex = new RegExp(`^${searchWords}.+`, "im");
    return regex;
};

const filterByQuery = (data: {
    regexp: RegExp;
    searchable: string | string[];
}) => {
    const regex = data?.regexp;
    const searchable: string = Array.isArray(data?.searchable)
        ? (data.searchable || []).join(" ")
        : data?.searchable || "";

    const searchableWithoutAccess = stringLatinize(searchable);
    return searchableWithoutAccess.search(regex) !== -1;
};

const removeAcccent = (value: string) => {
    return (value || "").normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const searchHelper = {
    getSearchRegex,
    filterByQuery,
    removeAcccent
};
