import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class HxSelectedLineService {
    private selectedLine$$ = new BehaviorSubject<number | undefined>(undefined);

    selectedLine$ = this.selectedLine$$.asObservable();

    set selectedLine(id: number | undefined) {
        this.selectedLine$$.next(id);
    }
    get selectedLine() {
        return this.selectedLine$$.getValue();
    }
}
