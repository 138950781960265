import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { LoadingToasterService } from "./loading-toaster.service";
import { ToasterComponent } from "./toaster.component";
import { ToasterService } from "./toaster.service";

/**
 * The app module defines the root module of the application along with metadata about the module.
 */
@NgModule({
    imports: [CommonModule, MatSnackBarModule],
    declarations: [ToasterComponent],
    exports: [ToasterComponent],
    providers: [ToasterService, LoadingToasterService]
})
export class SharedUiToasterModule {}
