import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HubspotService } from "../services/hubspot.service";

@Injectable()
export class HubspotParamsCheck {
    constructor(
        private router: Router,
        private hubspotService: HubspotService
    ) {}

    canActivate(route: ActivatedRouteSnapshot) {
        const portalId: string = route.queryParams["portalId"];

        if (portalId?.length) {
            return this.hubspotService.refreshToken(portalId).pipe(
                map(res => !!res?.ok),
                catchError(() => of(false)),
                map(ok => {
                    if (!ok) {
                        this._redirect();
                    }
                    return ok;
                })
            );
        }
        this._redirect();
        return false;
    }

    private _redirect() {
        this.router.navigate(["/unauthorized"], {
            replaceUrl: true
        });
    }
}
