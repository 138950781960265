const SIGNED_ATTACHMENT_URL_VALID_FRAGMENT =
    /https:\/\/harvestr-[a-z0-9-]{2,30}\.s3[0-9a-zA-Z-.]*\.amazonaws\.com/g;

export const validateSignedUrl = (url: string) => {
    if (url.match(SIGNED_ATTACHMENT_URL_VALID_FRAGMENT)) {
        return url;
    } else {
        return null;
    }
};

export const isUrlASignedUrl = (url: string) => {
    return url.match(SIGNED_ATTACHMENT_URL_VALID_FRAGMENT) ? true : false;
};
