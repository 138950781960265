import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import * as DOMPurify from "dompurify";

@Pipe({ name: "safeHtml" })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {}
    transform(value: string) {
        if (value?.length) {
            value = value.replace(/&nbsp;/gi, " ");
            // .replace(
            //     /href=("|') {0,6}http:\/\//gi,
            //     (full, match) => `href=${match}https://`
            // );
        }
        // return value;
        const sanitizedContent = DOMPurify.sanitize(value);
        return this.sanitized.bypassSecurityTrustHtml(sanitizedContent);
    }
}
