import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiAtachmentPresignReponse } from "@harvestr-client/shared/model/api";
import { validateSignedUrl } from "@harvestr-client/shared/shared/util-helper";
import { map, Observable, switchMap } from "rxjs";

@Injectable()
export abstract class ImageUploadAbstractServicePorts {
    constructor(protected http: HttpClient) {}

    protected _fetchAttachmentSignedUrl$(_file: {
        name: string;
        type: string;
    }): Observable<ApiAtachmentPresignReponse> {
        throw new Error(
            "MISSING ImageUploadAbstractServicePorts IMPLEMENTATION"
        );
    }

    uploadImage$(file: File): Observable<ApiAtachmentPresignReponse> {
        const newFileName = this.generateFileName(file.name);
        return this._fetchAttachmentSignedUrl$({
            name: newFileName,
            type: file.type
        }).pipe(
            switchMap(presignRes => {
                const renamedFile = new File([file], newFileName, {
                    type: file.type
                });
                const validatedUrl = validateSignedUrl(
                    presignRes.signedRequest
                );
                if (validatedUrl) {
                    return this.http
                        .put(validatedUrl, renamedFile, {
                            headers: { "Content-Type": renamedFile.type }
                        })
                        .pipe(map(() => presignRes));
                } else {
                    throw new Error("Invalid signed url");
                }
            })
        );
    }

    protected generateFileName = (fileName: string) =>
        encodeURI(fileName.replace(/[^a-zA-Z0-9-_.]/g, ""));
}
