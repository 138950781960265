import { Injectable } from "@angular/core";
import { NavigationExtras, NavigationStart, Router } from "@angular/router";
import { Observable, Subject } from "rxjs";

export type ToastBase = {
    type: "success" | "info" | "error" | "problem";
    text: string;
};

interface ToastCustomPayload {
    message: string;
    action: string;
    duration?: number;
    navigate?: boolean;
    navigationOptions?: {
        commands: any[];
        extras?: NavigationExtras;
    };
}

export type ToastCustom = {
    type: "custom";
} & ToastCustomPayload;

export type ToastClear = {
    type: "clear";
};

export type Toast = ToastBase | ToastCustom | ToastClear;

/**
 * The alert service enables any component in the application to display alert
 * messages at the top of the page via the alert component.
 * It has methods for displaying success and error messages, and a getMessage()
 * method that returns an Observable that is used by the alert component to
 * subscribe to notifications for whenever a message should be displayed.
 */

@Injectable()
export class ToasterService {
    private subject = new Subject<Toast>();
    private keepAfterNavigationChange = false;

    constructor(private router: Router) {
        // clear alert message on route change
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false;
                } else {
                    // clear alert
                    this.subject.next({ type: "clear" });
                }
            }
        });
    }

    custom(data: ToastCustomPayload, keepAfterNavigationChange?: boolean) {
        this.keepAfterNavigationChange = keepAfterNavigationChange ?? false;
        this.subject.next({ type: "custom", ...data });
    }

    success(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: "success", text: message });
    }

    info(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: "info", text: message });
    }

    error(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: "error", text: message });
    }

    problem(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: "problem", text: message });
    }

    getMessage(): Observable<Toast> {
        return this.subject.asObservable();
    }
}
