import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    OnChanges
} from "@angular/core";
import { ProfilePictureData } from "@harvestr-client/shared/model/app";

type Size = "inline" | "medium" | "small" | "big" | "huge";

@Component({
    selector: "hx-profile-picture",
    templateUrl: "./profile-picture.component.html",
    styleUrls: ["./profile-picture.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfilePictureComponent implements OnChanges {
    @HostBinding("class.profile-picture") profilePictureClass = true;
    @Input() person: ProfilePictureData | undefined;
    @Input() size: Size = "medium";
    @Input() withTooltip = false;

    pictureSizeClass = "round-picture-medium";
    tooltip = "";
    substringName = "";

    private getSizeClass(size: Size) {
        switch (size) {
            case "inline":
                return "round-picture-inline";
            case "small":
                return "round-picture-small";
            case "big":
                return "round-picture-big";
            case "huge":
                return "round-picture-huge";
            default:
                return "round-picture-medium";
        }
    }

    ngOnChanges() {
        this.pictureSizeClass = this.getSizeClass(this.size);
        this.substringName =
            this.person?.name?.substring(0, 2) ||
            this.person?.email?.substring(0, 2) ||
            "";
        if (this.withTooltip) {
            this.tooltip = this.person?.name?.length
                ? `${this.person?.name} (${this.person?.email})`
                : `${this.person?.email}`;
        } else {
            this.tooltip = "";
        }
    }
}
