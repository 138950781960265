import { Directive, HostBinding, Input } from "@angular/core";
import { Color } from "@harvestr-client/shared/model/api";
import { hxColorClassMap } from "../helpers/hx-colors.helper";

@Directive({
    selector: "[hxColor]"
})
export class HxColorDirective {
    @Input() set hxColor(v: Color | undefined) {
        const colorClass = hxColorClassMap[v || ""];
        if (colorClass) {
            this.colorClass = colorClass;
        }
    }
    @HostBinding("class") colorClass = "";
}
