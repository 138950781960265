import { HxMenuEl } from "@harvestr-client/shared/model/app";
import { Deal, DealEl } from "../models";

export const hxDealElementsBuilder = (deal: Deal): HxMenuEl<DealEl>[] => {
    const isUnpersonified =
        deal.companies?.length === 0 && deal.contacts?.length === 0;
    return [
        {
            type: "DEFAULT",
            id: deal.id,
            value: `${deal?.name} ${
                isUnpersonified ? "(no entity bound)" : ""
            }`,
            disable: isUnpersonified,
            el: {
                id: deal.id,
                name: deal?.name
            }
        }
    ];
};
