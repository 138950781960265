import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SubSink } from "@harvestr-client/shared/shared/util-helper";
import { switchMap, tap } from "rxjs";
import {
    DisplayableFeedback,
    HubspotApiFeedback
} from "../models/feedbacks.model";
import { HubspotService } from "../services/hubspot.service";

@Component({
    templateUrl: "./hubspot-list.component.html",
    styleUrls: ["./hubspot-list.component.scss"]
})
export class HubspotListComponent implements OnInit, OnDestroy {
    trackById = (i: number, item: { id: string }) => item.id;

    private subs = new SubSink();

    feedbackList: DisplayableFeedback[] = [];

    constructor(
        private hubspotService: HubspotService,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        const watchQueryParams$ = this.route.queryParams.pipe(
            switchMap(queryParam => {
                const associatedObjectType = queryParam["associatedObjectType"];
                const companyName = queryParam["name"];
                const userEmail = queryParam["email"];
                const dealId = queryParam["associatedObjectId"];
                const portalId = queryParam["portalId"];

                return this.hubspotService.fetchFeedbacks$(portalId, {
                    associatedObjectType,
                    name: companyName,
                    email: userEmail,
                    hs_object_id: dealId
                });
            }),
            tap((feedbackList: HubspotApiFeedback[]) => {
                this.feedbackList = feedbackList.map(feedback => {
                    return {
                        id: feedback.objectId,
                        title: feedback.title,
                        link: feedback.link,
                        createdAt: feedback.createdAt,
                        caption: feedback.caption,
                        channel: feedback.channel,
                        status: feedback.properties.findLast(property => {
                            return (
                                property.label === "State" ||
                                property.label === "Status"
                            );
                        }).value
                    };
                });
            })
        );

        this.subs.add(watchQueryParams$.subscribe());
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    onFeedbackClick(feedback: DisplayableFeedback) {
        window.open(feedback.link, "_blank");
    }
}
