import {
    AfterViewInit,
    ContentChild,
    ContentChildren,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    QueryList
} from "@angular/core";
import { SubSink } from "@harvestr-client/shared/shared/util-helper";
import { fromEvent } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { HxSelectedLineService } from "../hx-selected-line.service";
import { HxMenuInputDirective } from "./hx-menu-input.directive";
import { HxMenuLineDirective } from "./hx-menu-line.directive";

@Directive({
    selector: "[hxMenu]"
})
export class HxMenuDirective implements AfterViewInit, OnDestroy {
    @Input({ required: true }) hxMenu: undefined;
    @Output() selectionChanges = new EventEmitter<number>();

    @ContentChild(HxMenuInputDirective) inputFocus:
        | HxMenuInputDirective
        | undefined;
    @ContentChildren(HxMenuLineDirective, { descendants: true })
    hxMenuLines: QueryList<HxMenuLineDirective> | undefined;

    private subs = new SubSink();

    constructor(
        private el: ElementRef,
        private db: HxSelectedLineService
    ) {}

    ngAfterViewInit() {
        if (!this.inputFocus) {
            this.el.nativeElement.focus();
        }
        this._listenToInputChange(this.el);
        this._listenToSelected(this.el);
    }

    private _listenToSelected(el: ElementRef) {
        if (!el) {
            return;
        }
        const arrowKeyEvent$ = fromEvent<KeyboardEvent>(
            el.nativeElement,
            "keydown"
        ).pipe(
            // tap(e => {
            //     console.log(e);
            // }),
            filter(e => e.key === "Enter")
        );
        this.subs.add(
            arrowKeyEvent$.subscribe(_ => {
                if (typeof this.db.selectedLine === "number") {
                    this.selectionChanges.emit(this.db.selectedLine);
                }
            })
        );
    }

    private _listenToInputChange(el: ElementRef) {
        if (!el) {
            return;
        }
        // console.log(el);
        const watchArrowKeyEvent$ = fromEvent<KeyboardEvent>(
            el.nativeElement,
            "keydown"
        ).pipe(
            filter(
                e =>
                    !e.shiftKey &&
                    !e.ctrlKey &&
                    !e.altKey &&
                    !e.metaKey &&
                    (e.code === "ArrowDown" || e.code === "ArrowUp")
            ),
            tap((e: KeyboardEvent) => {
                const viewedIndexes = this.hxMenuLines
                    ? this.hxMenuLines
                          .toArray()
                          .map(e_ => e_.tabIndex)
                          .sort((a, b) => (a || 0) - (b || 0))
                    : [];
                let newSelectedIndex = null;
                if (!viewedIndexes.some(e_ => e_ === this.db.selectedLine)) {
                    newSelectedIndex = viewedIndexes[0];
                }
                const i = viewedIndexes.findIndex(
                    e_ => e_ === this.db.selectedLine
                );
                switch (e.code) {
                    case "ArrowDown":
                        if (i === viewedIndexes.length - 1) {
                            newSelectedIndex = viewedIndexes[0];
                        } else {
                            newSelectedIndex = viewedIndexes[i + 1];
                        }
                        break;
                    case "ArrowUp":
                        if (i === 0) {
                            newSelectedIndex =
                                viewedIndexes[viewedIndexes.length - 1];
                        } else {
                            newSelectedIndex = viewedIndexes[i - 1];
                        }
                        break;
                }
                this.db.selectedLine = newSelectedIndex || undefined;
            })
        );
        this.subs.add(watchArrowKeyEvent$.subscribe());
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
